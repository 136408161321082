import request from "@/util/request";
const labelPrefix = "/v1/member/labels";

export default {
  label(params) {
    return request.get(`${labelPrefix}`, params);
  },

  labelsById(id) {
    return request.get(`${labelPrefix}/${id}`);
  },
};
