import actions from "@/store/module/brand/action.js";

export default {
  namespaced: true,
  state: {
    // 品牌列表
    brandList: [],
    // 根据id获取品牌主页
    brandHome: {},
  },
  actions,
};
