<template>
  <div v-if="list instanceof Array && list.length !== 0" class="list_container">
    <div
      v-for="(item, index) in list"
      :key="index"
      class="list flex flex-between"
      @click="triggerEnterDetailPageEvent(index)"
    >
      <div v-if="JSON.stringify(item) !== '{}'" class="content flex flex-item-2 flex-column flex-around">
        <div v-if="typeof item.content === 'object'" class="caption line2">
          {{ item.content.title || item.content.name }}
        </div>
        <div v-else-if="item.topic" class="caption line2">{{ item.topic.name }}</div>
        <div v-else class="caption line2">{{ item.title }}</div>
        <div class="info flex flex-between flex-middle">
          <div v-if="typeof item.content === 'object'" class="left flex flex-middle">
            <span v-if="item.content.contentSourceName" class="content_source_name"
              >{{ item.content.contentSourceName }}&nbsp;&nbsp;</span
            >
            <span>{{ item.content.publishedTime | formatTimeStamp }}</span>
          </div>
          <div v-else-if="item.topic" class="left flex flex-middle">
            <span v-if="item.topic.contentSourceName" class="content_source_name"
              >{{ item.topic.contentSourceName }}&nbsp;&nbsp;</span
            >
            <span>{{ item.topic.publishedTime | formatTimeStamp }}</span>
          </div>
          <div v-else class="left flex flex-middle">
            <span v-if="item.contentSourceName" class="content_source_name"
              >{{ item.contentSourceName }}&nbsp;&nbsp;</span
            >
            <span>{{ item.publishedTime | formatTimeStamp }}</span>
          </div>

          <div class="right">
            <img src="@/assets/images/icon/list-views.png" alt="观看量" />
            <span v-if="typeof item.content === 'object'">{{ item.content.readNum | filterNumber }}</span>
            <span v-else-if="item.topic">{{ item.topic.readNum | filterNumber }}</span>
            <span v-else>{{ item.readNum | filterNumber }}</span>
          </div>
        </div>
      </div>
      <div v-if="JSON.stringify(item) !== '{}'" class="pics flex-item-1">
        <div class="coverImg">
          <Cover
            v-if="typeof item.content === 'object'"
            :src="item.content.coverImgUrl"
            :alt="item.content.title"
            height="60%"
          ></Cover>
          <Cover v-else-if="item.topic" :src="item.topic.coverImgUrl" :alt="item.topic.name" height="60%"></Cover>
          <Cover v-else :src="item.coverImgUrl" :alt="item.title" height="60%"></Cover>
          <div
            v-if="
              (item.content && item.content.viewableMember === 'vip1') ||
                (item.topic && item.topic.viewableMember === 'vip1') ||
                (item && item.viewableMember === 'vip1')
            "
            class="vip"
          >
            <img src="@/assets/images/icon/VIP.png" :alt="'VIP' + index" />
          </div>
          <div v-if="item.contentType && item.contentType === 'video'" class="duration flex flex-center flex-middle">
            <img src="@/assets/images/icon/list-video.png" alt="" />&nbsp;
            <span>{{ item.content.videoDuration | filterSeconds }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cover from "@/components/Cover.vue";

export default {
  components: {
    Cover,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      duration: 0,
    };
  },
  computed: {},

  methods: {
    // 进入详情页
    triggerEnterDetailPageEvent(index) {
      this.$emit("enterDetailPage", index);
    },
  },
};
</script>

<style lang="less" scoped>
.coverImg {
  width: 100%;
  height: 3.5rem;
}
.list {
  margin-bottom: 1rem;
}
.list .content .caption {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}
.list .left,
.list .right {
  font-size: 0.6rem;
  color: #7d7e80;
}
.list .left .content_source_name {
  display: inline-block;
  width: 4.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0.2rem;
}
.list .right img {
  width: 0.8rem;
  height: 0.8rem;
  vertical-align: middle;
}
.list .pics {
  margin-left: 0.75rem;
  // background-color: #000;
  // position: relative;
}
.list .pic_item,
.list .pics img {
  width: 100%;
  height: 100%;
  vertical-align: unset;
}
.list .pics .vip {
  position: absolute;
  left: 0;
  top: 0;
  width: 1.5rem;
  height: 0.75rem;
}
.list .pics .duration {
  font-size: 0.4rem;
  color: #fff;
  position: absolute;
  right: 0.25rem;
  bottom: 0.25rem;
  img {
    width: 0.7rem;
    height: 0.7rem;
  }
}
</style>
