import request from "@/util/request";

const channelPrefix = "/v1/member/channels";

export default {
  channel(params) {
    return request.get(`${channelPrefix}`, params);
  },
  getChannelContentList(id, params) {
    return request.get(`${channelPrefix}/contentList/${id}`, params);
  },
  getChannelById(id) {
    return request.get(`${channelPrefix}/${id}`);
  },
};
