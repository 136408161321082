export default {
  "zh-CN": {
    // 底部tab栏
    tab: {
      home: "首页",
      book: "绘本",
      toy: "玩具",
      course: "课程",
    },
    // 提示语
    message: {
      hello: "你好, 中国",
    },
  },
};
