import { ResultCodeEnum, latestPicksApi, preferredTopicsApi, worksCaseApi } from "@/api/index.js";
import sdkApi from "@/util/wxShare.js";
export default {
  // 最新精选列表
  async getLatestPicksList({ state }, payload) {
    const res = await latestPicksApi.latestPicks(payload);
    if (res.code === ResultCodeEnum.success.code) {
      state.latestPicksList.push(...res.data.rows);
      state.latestPicksPageInfo = res.data.pageInfo;
    }
  },
  // 根据id获取对应最新精选
  async getLatestPicksById({ state }, id) {
    const res = await latestPicksApi.latestPicksById(id);
    if (res.code === ResultCodeEnum.success.code) {
      state.latestPicks = res.data;
    }
  },
  // 设计风格列表
  async getPreferredTopicsList({ state }, payload) {
    const res = await preferredTopicsApi.preferredTopics(payload);
    if (res.code === ResultCodeEnum.success.code) {
      state.preferredTopicsList.push(...res.data.rows);
      state.preferredTopicsPageInfo = res.data.pageInfo;
    }
  },
  // 根据id获取对应设计风格
  async getPreferredTopicsById({ state }, payload) {
    const res = await preferredTopicsApi.preferredTopicsById(payload.id, payload.params);
    if (res.code === ResultCodeEnum.success.code) {
      state.preferredTopics = res.data;
    }
  },
  // 作品案例列表
  async getWorksCaseList({ state }, payload) {
    const res = await worksCaseApi.worksCase(payload);
    if (res.code === ResultCodeEnum.success.code) {
      state.worksCaseList.push(...res.data.rows);
      state.worksCasePageInfo = res.data.pageInfo;
    }
  },
  // 根据id获取对应作品案例信息
  async getWorksCaseById({ rootState, state }, payload) {
    const res = await worksCaseApi.worksCaseById(payload.id, payload.params);
    if (res.code === ResultCodeEnum.success.code) {
      state.worksCase = res.data;
      if (rootState.route.query.fromShare) {
        document.title = res.data.title;
        rootState.fullPath = rootState.route.fullPath;
      } else {
        if (rootState.route.fullPath.indexOf("?") > 0) {
          rootState.fullPath = rootState.route.fullPath + "&fromShare=true";
        } else {
          rootState.fullPath = rootState.route.fullPath + "?fromShare=true";
        }
      }
      // 调用微信分享接口
      sdkApi.getJSSDK({
        title: "意米家 Foresee Find",
        desc: state.worksCase.title,
        link: window.location.href.split("#")[0] + `?ts=${Date.now()}#` + rootState.fullPath,
        imgUrl: "http://oss-benma-test.benma-code.com/vl/img/xHfM1mSlOmtFCqEsJMuwMMIxtW68odgH.png",
      });
    }
  },
};
