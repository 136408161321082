import request from "@/util/request";

const latestPicksPrefix = "/v1/member/homepageRecommends";

export default {
  latestPicks(params) {
    return request.get(`${latestPicksPrefix}`, params);
  },
  latestPicksById(id) {
    return request.get(`${latestPicksPrefix}/${id}`);
  },
};
