import request from "@/util/request";

const contentCommentPrefix = "/v1/member/contentComments";

export default {
  contentComment(params) {
    return request.get(`${contentCommentPrefix}`, params);
  },
  addContentComment(params) {
    return request.post(`${contentCommentPrefix}`, params);
  },
  replyComment(params) {
    return request.post(`${contentCommentPrefix}/replyComment`, params);
  },
  deleteContentCommentById(ids) {
    return request.delete(`${contentCommentPrefix}/${ids}`);
  },
  contentCommentById(id) {
    return request.get(`${contentCommentPrefix}/${id}`);
  },
};
