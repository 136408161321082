import request from "@/util/request";

const imageTextPrefix = "/v1/member/imageTexts";

export default {
  imageText(params) {
    return request.get(`${imageTextPrefix}`, params);
  },
  imageTextById(id) {
    return request.get(`${imageTextPrefix}/${id}`);
  },
};
