import request from "@/util/request";
const preferredTopicsPrefix = "/v1/member/preferredTopics";

export default {
  preferredTopics(params) {
    return request.get(`${preferredTopicsPrefix}`, params);
  },

  preferredTopicsById(id, params) {
    return request.get(`${preferredTopicsPrefix}/${id}`, params);
  },
};
