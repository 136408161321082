import request from "@/util/request";
const internationalPrefix = "/v1/member/internationalTrends";

export default {
  internationalTrends(params) {
    return request.get(`${internationalPrefix}`, params);
  },
  getInternationalTrendById(id) {
    return request.get(`${internationalPrefix}/getInternationalTrend/${id}`);
  },
  internationalTrendById(id) {
    return request.get(`${internationalPrefix}/${id}`);
  },
};
