import { vipApi, ResultCodeEnum } from "@/api/index.js";
import wxPayApi from "@/util/wxPay.js";
import { Dialog } from "vant";

export default {
  // 获取会员等级信息
  async getVipDetail({ state }) {
    const res = await vipApi.getVip();
    if (res.code === ResultCodeEnum.success.code) {
      state.vipDetail = res.data;
    }
  },
  // 加入会员
  async joinVip({ state }, params) {
    const res = await vipApi.joinVip(params);
    if (res.code === ResultCodeEnum.success.code) {
      Dialog.close();
      wxPayApi.wxPay(res.data);
    }
  },
};
