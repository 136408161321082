import request from "@/util/request";

const vipPrefix = "/v1/member/vips";

export default {
  getVip(params) {
    return request.get(`${vipPrefix}/getVip`, params);
  },
  joinVip(params) {
    return request.post(`${vipPrefix}/joinVip`, params);
  },
  wxOrderQuery(params) {
    return request.post(`${vipPrefix}/wxOrderQuery`, params);
  },
};
