import actions from "@/store/module/designer/action.js";

export default {
  namespaced: true,
  state: {
    // 设计师热门作品
    designerHotWorks: [],
    // 设计师详情
    designerDetail: {},
    designerHotWorksPageInfo: {},
  },
  actions,
};
