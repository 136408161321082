import request from "@/util/request.js";
const videoUrlPrefix = "v1/member/videos";

export default {
  // 视频
  video: (params) => {
    return request.get(`${videoUrlPrefix}`, params);
  },
  videoById: (id) => {
    return request.get(`${videoUrlPrefix}/${id}`);
  },
};
