import { ResultCodeEnum, searchApi } from "@/api/index.js";

export default {
  // 搜索
  async getSearchResultList({ state, rootState }) {
    state.searchQuery.keyword = rootState.route.query.keyword || state.searchValue.trim();
    if (state.searchQuery.pageNum === 0) {
      state.searchResultList = [];
    }
    const res = await searchApi.homeSearch(state.searchQuery);
    if (res.code === ResultCodeEnum.success.code) {
      if (state.searchQuery.pageNum === 0) {
        state.searchResultList = res.data.rows;
      } else {
        state.searchResultList.push(...res.data.rows);
      }
      state.searchResultPageInfo = res.data.pageInfo;
    }
  },
};
