import Enum from "@/enum";

// api错误码
export const ResultCodeEnum = {
  success: new Enum("OK", "操作成功"),
  fail: new Enum("BUSINESS_FAIL", "操作失败"),
  inputError: new Enum("BAD_USER_INPUT", "参数错误"),
  error: new Enum("INTERNAL_SERVER_ERROR", "系统繁忙，请稍后再试"),
  unauthenticated: new Enum("UNAUTHENTICATED", "未登录"),
  forbidden: new Enum("FORBIDDEN", "未授权"),
  accountDisabled: new Enum("ACCOUNT_DISABLED", "账号已被禁用"),
  kickout: new Enum("KICKOUT", "被踢出登录"),
  vipContent: new Enum("VIP_CONTENT", "会员可见"),
};

export const mapStyle = {};

export const AGELIST = [
  {
    min: 0,
    max: 3,
  },
  {
    min: 4,
    max: 6,
  },
  {
    min: 7,
    max: 10,
  },
];
