import request from "@/util/request";

const contentPraisePrefix = "/v1/member/contentPraises";

export default {
  cancelContentPraise(params) {
    return request.delete(`${contentPraisePrefix}/cancelContentPraise`, params);
  },
  addContentPraise(params) {
    return request.post(`${contentPraisePrefix}/addContentPraise`, params);
  },
  isPraise(params) {
    return request.get(`${contentPraisePrefix}/isPraise`, params);
  },
};
