import { Toast } from "vant";
import { ResultCodeEnum, vipApi } from "@/api";
import store from "@/store/store.js";
import vm from "@/page/index/index.js";
function onBridgeReady(data, cb) {
  const params = JSON.parse(data);
  WeixinJSBridge.invoke(
    "getBrandWCPayRequest",
    {
      appId: params.appId, // 公众号名称，由商户传入
      timeStamp: params.timeStamp, // 时间戳，自1970年以来的秒数
      nonceStr: params.nonceStr, // 随机串
      package: params.package,
      signType: params.signType, // 微信签名方式：
      paySign: params.paySign, // 微信签名
    },
    (res) => {
      // 这个步骤非常重要，调试期间会报上相应的错误信息，因为微信调试工具是无法实际支付的，并且微信上是无法调试的，只能在这里alert
      // alert(res.err_msg);
      if (res.err_msg === "get_brand_wcpay_request:ok") {
        // 使用以上方式判断前端返回,微信团队郑重提示：
        // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        // 查询订单
        const intervalId = setInterval(() => {
          // this.$store.dispatch("checkWxOrderQuery", { orderNo: params.orderNo });
          vipApi.wxOrderQuery({ orderNo: params.orderNo }).then((res) => {
            if (res.code === ResultCodeEnum.success.code) {
              if (res.data === "SUCCESS") {
                clearInterval(intervalId);
                Toast.success("支付成功");
                // 强制刷新
                // window.location.reload();
                // store.state.vipPaySuccess = true;
                // store.state.loginUser.isVip = true;
                store.dispatch("getLoginUserInfo");
              }
              if (
                res.data === "REFUND" ||
                res.data === "NOTPAY" ||
                res.data === "CLOSED" ||
                res.data === "REVOKED" ||
                res.data === "PAYERROR"
              ) {
                Toast.fail("支付失败");
              }
            }
          });
        }, 1000);
      } else {
        // 统计取消支付
        vm.$uweb.trackEvent("微信支付窗口", "取消微信支付", "关闭弹出的微信支付窗口");
      }
    }
  );
}
// WeixinJSBridge 是微信内置对象，支付功能只能在微信端才能使用
function wxPay(data) {
  // 需要先判断是否是微信浏览器，并且微信内置包是否加载完成
  // 有时会出现当触发事件的时候微信内置包还未加载完成，必须加上下面判断
  if (typeof WeixinJSBridge === "undefined") {
    if (document.addEventListener) {
      document.addEventListener("WeixinJSBridgeReady", onBridgeReady, false);
    } else if (document.attachEvent) {
      document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
      document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
    }
  } else {
    onBridgeReady(data);
  }
}

export default {
  wxPay,
};
