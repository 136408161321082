import request from "@/util/request";

const studyTourPrefix = "/v1/member/studyTours";

export default {
  studyTour(params) {
    return request.get(`${studyTourPrefix}`, params);
  },
  studyTourById(id) {
    return request.get(`${studyTourPrefix}/${id}`);
  },
  getStudyTourReservation(id) {
    return request.get(`${studyTourPrefix}/getStudyTourReservation/${id}`);
  },
  studyTourReservation(params) {
    return request.post(`${studyTourPrefix}/studyTourReservation`, params);
  },
  myInvitation(params) {
    return request.get(`${studyTourPrefix}/myInvitation`, params);
  },
};
