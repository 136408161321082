import request from "@/util/request";

const sdkPrefix = "/v1/base/wx/jssdkconfig";

export default {
  getJSSDK(params) {
    return request.get(`${sdkPrefix}/jssdk`, params);
  },
  getJSSDKPay(params) {
    return request.get(`${sdkPrefix}/pay`, params);
  },
};
