import actions from "@/store/module/product/action.js";

export default {
  namespaced: true,
  state: {
    // 产品列表
    productList: [],
    productDetail: {},
    productPageInfo: {},
  },
  actions,
};
