<template>
  <div
    :class="[
      'video_container',
      'flex',
      'flex-column',
      videoList.length !== 0 ? 'max_content_height' : 'percent_height',
    ]"
  >
    <van-divider />
    <!-- 可选选项区域 -->
    <div class="select">
      <MenuBar :options="options" @handleEvent="showPopup"></MenuBar>
      <!-- 【全部空间】 的弹出层 -->
      <van-popup
        key="space"
        v-model="showSpace"
        class="space_popup"
        position="bottom"
        :style="{ height: '70%' }"
        @click-overlay="clickOverlaySpace"
      >
        <div class="area flex flex-column">
          <div class="content flex-item">
            <div class="title flex flex-between flex-middle">
              <span>选择{{ currentPopupType }}</span>
              <a href="javascript:;" @click="clearSpaceAllActiveTag">清除</a>
            </div>
            <div class="options_space flex flex-wrap flex-middle">
              <van-tag
                v-for="(item, index) in spaces"
                :key="index"
                :class="['option_item_space' + item.id, 'line1']"
                @click="(e) => changeSpaceTagState(e, item.id)"
                >{{ item.name }}</van-tag
              >
            </div>
          </div>

          <van-button
            :style="{ marginBottom: this.$store.state.userAgent === 'Android' ? '0' : '3rem' }"
            class="confirm"
            @click="hideSpacePopup"
            >确 定</van-button
          >
        </div>
      </van-popup>
      <!-- 【全部风格】 的弹出层 -->
      <van-popup
        key="style"
        v-model="showStyle"
        class="style_popup"
        position="bottom"
        :style="{ height: '70%' }"
        @click-overlay="clickOverlayStyle"
      >
        <div class="area flex flex-column">
          <div class="content flex-item">
            <div class="title flex flex-between flex-middle">
              <span>选择{{ currentPopupType }}</span>
              <a href="javascript:;" @click="clearStyleAllActiveTag">清除</a>
            </div>
            <div class="options_style flex flex-wrap  flex-middle">
              <van-tag
                v-for="(item, index) in styles"
                :key="index"
                :class="['option_item_style' + item.id, 'line1']"
                @click="(e) => changeStyleTagState(e, item.id)"
                >{{ item.name }}</van-tag
              >
            </div>
          </div>
          <van-button
            :style="{ marginBottom: this.$store.state.userAgent === 'Android' ? '0' : '3rem' }"
            class="confirm"
            @click="hideStylePopup"
            >确 定</van-button
          >
        </div>
      </van-popup>
    </div>
    <Empty v-if="pageInfo.totalCount === 0"></Empty>
    <!-- 视频区域 -->
    <div v-else>
      <div v-if="videoList.length !== 0" class="video_list">
        <div v-for="(item, index) in videoList" :key="index" class="video_item">
          <!-- 封面 -->
          <div class="coverImg" @click="clickCoverImg(item.contentId)">
            <div class="duration flex flex-center flex-middle">
              <img src="@/assets/images/icon/list-video.png" alt="" />&nbsp;
              <span>{{ item.videoDuration | filterSeconds }}</span>
            </div>
            <Cover :src="item.coverImgUrl" alt="视频封面图" height="60%"></Cover>
            <div v-show="item.viewableMember === 'vip1'" class="vip">
              <img src="@/assets/images/icon/VIP.png" alt="VIP" />
            </div>
          </div>
          <!-- 内容区域 -->
          <div class="content">
            <div class="title line1">
              {{ item.contentTitle }}
            </div>
            <div class="info flex flex-between flex-middle">
              <span>{{ item.contentSourceName }}</span>
              <div class="operate flex flex-bewteen flex-middle">
                <div class="share" @click="onShare">
                  <img src="@/assets/images/icon/list-share.png" alt="列表-分享" />
                </div>
                <div class="like" @click="clickLikeButton(item.contentId, index)">
                  <img v-if="!item.isPraise" src="@/assets/images/icon/detail-praise.png" alt="详情-点赞1" />
                  <img v-else src="@/assets/images/icon/detail-praise2.png" alt="详情-点赞2" />
                  <span class="count">{{ item.praiseNum }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <van-loading v-if="available === 0 && !pageInfo.isLast" size="24px" color="#1989fa">加载中...</van-loading>
    </div>
    <!-- 遮挡层指示 -->
    <ShareTips></ShareTips>
  </div>
</template>

<script>
import MenuBar from "@/components/MenuBar.vue";
import Empty from "@/components/Empty.vue";
import Cover from "@/components/Cover.vue";
import ShareTips from "@/components/ShareTips.vue";
import { Dialog, Toast } from "vant";
import filterApi from "@/util/filter";
import { ResultCodeEnum, channelApi, praiseApi, labelApi } from "@/api";
export default {
  components: {
    Cover,
    MenuBar,
    ShareTips,
    Empty,
  },
  props: {
    channelId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      // 控制弹出层的显示与隐藏
      showStyle: false,
      showSpace: false,
      query: {
        pageNum: 0,
        pageSize: 100,
        labelType: "space",
      },
      options: ["全部空间", "全部风格"],
      currentPopupType: "",
      // 全部空间
      spaces: [],
      spaceLabelIds: [],
      tempSpaceLabelIds: [],
      // 全部风格
      styles: [],
      styleLabelIds: [],
      tempStyleLabelIds: [],
      videoList: [],
      pageInfo: {},
      contentQuery: {
        pageNum: 0,
        pageSize: 6,
        spaceLabelIds: "",
        styleLabelIds: "",
      },
      available: 0,
    };
  },
  watch: {
    channelId() {
      this.spaceLabelIds = [];
      this.styleLabelIds = [];
      this.available = 0;
      this.contentQuery.pageNum = 0;
      this.videoList = [];
      this.pageInfo = {};
      this.getContentList();
    },
  },
  mounted() {
    this.getLabelsList();
    this.getContentList();
    document.querySelector(".router_view").addEventListener("scroll", this.scrollEvent);
  },
  methods: {
    // 转发
    onShare() {
      this.$store.state.showOverlay = true;
    },
    scrollEvent() {
      // 获取滚轮位置
      const height1 = document.querySelector(".router_view").scrollTop;
      // 文档高度
      const height2 = document.querySelector(".router_view").scrollHeight;
      // 可视区域
      const height3 = document.querySelector(".router_view").clientHeight;
      // 如果滚动到最低（这里设置离最底还有100距离才触发函数）
      // available条件是为了防止触底时一直不断地请求。因此，请求一次后available为0，直到滚动到离底部超过100距离（即数据加载完后）才设为1
      if (height3 + height1 >= height2 && this.available && !this.pageInfo.isLast) {
        if (this.contentQuery.pageNum < this.pageInfo.totalPage) {
          this.contentQuery.pageNum++;
          this.available = 0;
          this.getContentList();
        }
      } else if (height3 + height1 < height2 - 30) {
        this.available = 1;
      }
    },

    // 获取对应频道列表
    async getContentList() {
      const res = await channelApi.getChannelContentList(this.channelId, this.contentQuery);
      if (res.code === ResultCodeEnum.success.code) {
        this.videoList.push(...res.data.rows);
        this.pageInfo = res.data.pageInfo;
      }
    },
    openVIP(id, viewableMember, contentSourceId, contentSourceBrandId) {
      Dialog.close();
      if (
        viewableMember === "vip1" &&
        !this.$store.state.loginUser.isVip &&
        !(
          (this.$store.state.myAuthentication !== null &&
            this.$store.state.myAuthentication.status === "audit_pass" &&
            this.$store.state.myAuthentication.type !== "brand" &&
            this.$store.state.myAuthentication.contentSourceId === contentSourceId) ||
          (this.$store.state.myAuthentication !== null &&
            this.$store.state.myAuthentication.status === "audit_pass" &&
            this.$store.state.myAuthentication.type === "brand" &&
            this.$store.state.myAuthentication.authenticationDetail.brandId === contentSourceBrandId)
        )
      ) {
        // 弹框 提示开通会员
        Dialog.alert({
          title: "仅VIP会员可看，邀请您加入VIP",
          message: `<div class="open_vip_label flex flex-center flex-middle" style="height: 2rem;border-radius: 0.2rem;background-color: rgb(245, 185, 105);margin-top:1rem;" ><p style="color: #81510a;">￥<span>${filterApi.centToYuan(
            this.$store.state.vipDetail.cost
          )}元/年</span>,开通VIP查看全部内容</p></div>`,
          allowHtml: true,
          closeOnClickOverlay: true,
          confirmButtonText: "取消",
        })
          .then(() => {
            this.$uweb.trackEvent("开通VIP查看全部内容", "取消", "设计师详情");
          })
          .catch(() => {});
        this.$nextTick(() => {
          document.querySelector(".open_vip_label").onclick = () => {
            this.$uweb.trackEvent("开通VIP查看全部内容", "支付", "设计师详情页");
            this.$store.dispatch("vip/joinVip", { vipRankCode: "VIP1", remark: "" });
          };
        });
      } else {
        this.$router.push(`/video_detail?id=${id}`);
      }
    },
    clickCoverImg(contentId) {
      const currentItem = this.videoList.find((item) => item.contentId === contentId);
      // 判断范围是否是普通用户还是注册用户
      if (currentItem.viewableMember === "member") {
        this.$router.push(`/video_detail?id=${contentId}`);
      } else {
        // 判断是否绑定了号码;
        if (!(this.$store.state.loginUser.loginPhone && this.$store.state.loginUser.loginPhone !== "")) {
          Dialog.confirm({
            message: "注册会员，获取更多进口品牌信息",
            confirmButtonText: "前往注册 >",
          })
            .then(() => {
              this.$uweb.trackEvent("注册会员，获取更多进口品牌信息", "前往注册", "设计师详情页");
              this.$router.push("/bind_phone");
            })
            .catch(() => {
              this.$uweb.trackEvent("注册会员，获取更多进口品牌信息", "取消", "设计师详情页");
            });
        } else {
          this.openVIP(
            currentItem.contentId,
            currentItem.viewableMember,
            currentItem.contentSourceId,
            currentItem.contentSourceBrandId
          );
        }
      }
    },
    // 点赞
    clickLikeButton(id, index) {
      // 已点赞
      if (this.videoList[index].isPraise) {
        this.$uweb.trackEvent("点赞图标", "取消点赞", `(首页视频频道)${this.videoList[index].contentTitle}`);
        this.cancelContentPraise(id, index);
      } else {
        // 未点赞
        this.$uweb.trackEvent("点赞图标", "点赞", `(首页视频频道)${this.videoList[index].contentTitle}`);
        this.addContentPraise(id, index);
      }
    },
    // 点赞
    async addContentPraise(id, index) {
      const res = await praiseApi.addContentPraise({ contentType: "video", contentId: id });
      if (res.code === ResultCodeEnum.success.code) {
        this.videoList[index].isPraise = true;
        this.videoList[index].praiseNum = res.data;
      }
    },
    // 取消点赞
    async cancelContentPraise(id, index) {
      const res = await praiseApi.cancelContentPraise({ contentType: "video", contentId: id });
      if (res.code === ResultCodeEnum.success.code) {
        this.videoList[index].isPraise = false;
        this.videoList[index].praiseNum = res.data;
      }
    },
    // 获取标签列表
    async getLabelsList() {
      // 获取空间
      const res = await labelApi.label(this.query);
      if (res.code === ResultCodeEnum.success.code) {
        this.spaces = res.data.rows;
      } else {
        Toast("获取失败");
      }
      // 获取风格
      this.query.labelType = "style";
      const result = await labelApi.label(this.query);
      if (result.code === ResultCodeEnum.success.code) {
        this.styles = result.data.rows;
      }
    },
    // 点击选项，显示弹出层
    showPopup(text) {
      this.currentPopupType = this.options.find((item) => item === text).slice(2);
      if (this.currentPopupType === "风格") {
        this.showStyle = true;
        this.showSpace = false;
      } else {
        this.showStyle = false;
        this.showSpace = true;
      }
    },
    // 【全部空间】 中的事件
    // 监听遮挡层点击事件
    changeSpaceTagState(e, id) {
      e.currentTarget.classList.toggle("active");
      // 保存当前被激活的标签
      if (e.currentTarget.classList.contains("active")) {
        this.tempSpaceLabelIds.push(id);
      } else {
        if (this.tempSpaceLabelIds.includes(id)) {
          const index = this.tempSpaceLabelIds.findIndex((item) => item === id);
          this.tempSpaceLabelIds.splice(index, 1);
        }
      }
    },
    // 点击确定
    hideSpacePopup() {
      // 删除重复的值
      this.tempSpaceLabelIds = [...new Set(this.tempSpaceLabelIds)];
      this.spaceLabelIds = JSON.parse(JSON.stringify(this.tempSpaceLabelIds));
      // 重新获取数据渲染
      this.contentQuery.spaceLabelIds = this.spaceLabelIds.join(",");
      this.contentQuery.styleLabelIds = this.styleLabelIds.join(",");
      this.contentQuery.pageNum = 0; // 重新从第一页请求
      this.videoList = [];
      this.getContentList();
      this.showSpace = false;
    },
    // 点击遮挡层 --- 不保存这一次操作状态
    clickOverlaySpace() {
      // 找出两个数组相同的元素
      let arr = [];
      arr = this.tempSpaceLabelIds.filter((item) => this.spaceLabelIds.indexOf(item) !== -1);
      const labelList = this.tempSpaceLabelIds.concat(this.spaceLabelIds);
      // 筛选出发生变化的标签
      arr.forEach((item) => {
        while (labelList.indexOf(item) !== -1) {
          labelList.splice(labelList.findIndex((label) => label === item), 1);
        }
      });
      // 找到发生变化的标签对应的id
      labelList.forEach((item) => {
        document.querySelector(`.option_item_space${item}`).classList.toggle("active");
      });
      this.tempSpaceLabelIds = JSON.parse(JSON.stringify(this.spaceLabelIds));
    },
    // 点击清除按钮，删除所有被选中的选项标签的激活类
    clearSpaceAllActiveTag() {
      document.querySelector(".options_space").childNodes.forEach((item) => {
        item.classList.remove("active");
      });
      this.spaceLabelIds = [];
      this.tempSpaceLabelIds = [];
      // Dialog.alert({
      //   message: "已清除",
      // }).then(() => {
      this.contentQuery.spaceLabelIds = "";
      this.contentQuery.pageNum = 0; // 重新从第一页请求
      this.videoList = [];
      this.getContentList();
      this.showSpace = false;
      // });
    },

    // 【全部风格】
    // 监听遮挡层点击事件
    changeStyleTagState(e, id) {
      e.currentTarget.classList.toggle("active");
      // 保存当前被激活的标签
      if (e.currentTarget.classList.contains("active")) {
        this.tempStyleLabelIds.push(id);
      } else {
        if (this.tempStyleLabelIds.includes(id)) {
          const index = this.tempStyleLabelIds.findIndex((item) => item === id);
          this.tempStyleLabelIds.splice(index, 1);
        }
      }
    },
    // 点击确定
    hideStylePopup() {
      // 删除重复的值
      this.tempStyleLabelIds = [...new Set(this.tempStyleLabelIds)];
      this.styleLabelIds = JSON.parse(JSON.stringify(this.tempStyleLabelIds));
      // 重新获取数据渲染
      this.contentQuery.spaceLabelIds = this.spaceLabelIds.join(",");
      this.contentQuery.styleLabelIds = this.styleLabelIds.join(",");
      this.contentQuery.pageNum = 0; // 重新从第一页请求
      this.videoList = [];
      this.getContentList();
      this.showStyle = false;
    },
    // 点击遮挡层 --- 不保存这一次操作状态
    clickOverlayStyle() {
      // 找出两个数组相同的元素
      let arr = [];
      arr = this.tempStyleLabelIds.filter((item) => this.styleLabelIds.indexOf(item) !== -1);
      const labelList = this.tempStyleLabelIds.concat(this.styleLabelIds);
      // 筛选出发生变化的标签
      arr.forEach((item) => {
        while (labelList.indexOf(item) !== -1) {
          labelList.splice(labelList.findIndex((label) => label === item), 1);
        }
      });
      // 找到发生变化的标签对应的id
      labelList.forEach((item) => {
        document.querySelector(`.option_item_style${item}`).classList.toggle("active");
      });
      this.tempStyleLabelIds = JSON.parse(JSON.stringify(this.styleLabelIds));
    },
    // 点击清除按钮，删除所有被选中的选项标签的激活类
    clearStyleAllActiveTag() {
      document.querySelector(".options_style").childNodes.forEach((item) => {
        item.classList.remove("active");
      });
      this.styleLabelIds = [];
      this.tempStyleLabelIds = [];
      // Dialog.alert({
      //   message: "已清除",
      // }).then(() => {
      this.contentQuery.styleLabelIds = "";
      this.contentQuery.pageNum = 0; // 重新从第一页请求
      this.videoList = [];
      this.getContentList();
      this.showStyle = false;
      // });
    },
  },
};
</script>

<style lang="less" scoped>
.video_container {
  padding-bottom: 1rem;
  .select {
    margin-bottom: 0.6rem;
    .space_popup,
    .style_popup {
      padding: 0;
      .area {
        width: 100%;
        height: 100%;
        position: relative;
        padding: 1.5rem 0;
        .content {
          position: relative;
          overflow-y: auto;
          padding: 0 1rem;
        }
        .confirm {
          margin: 0 1rem;
        }
      }
    }
    .title {
      margin-bottom: 1rem;
      > span {
        font-size: 0.9rem;
        font-weight: 600;
      }
      > a {
        font-size: 0.7rem;
        color: #aaa;
      }
    }
    .options_space,
    .options_style {
      margin-bottom: 1rem;
      /deep/ .van-tag {
        text-align: center;
        display: block;
        font-size: 0.7rem;
        color: #000;
        line-height: 1.6rem;
        flex-basis: 28%;
        background-color: #f7f8fa;
        padding: 0.3rem 0.35rem;
      }
    }

    .options_space .active,
    .options_style .active,
    .confirm {
      color: #fff;
      background-color: rgb(51, 51, 51);
    }
  }
  .video_list .video_item {
    margin-bottom: 1.5rem;
    .coverImg {
      height: 10.5rem;
      .duration {
        z-index: 1;
        color: #fff;
        position: absolute;
        left: 6%;
        bottom: 4%;
        img {
          width: 0.7rem;
          height: 0.7rem;
        }
      } // the end of play
      .vip {
        width: 2.2rem;
        height: 1.2rem;
        position: absolute;
        left: 0;
        top: 0;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    } // the end of cover-img

    // 内容区域
    .content {
      margin: 0.8rem 0;
      .title {
        font-size: 0.9rem;
        font-weight: 600;
        color: rgb(70, 70, 70);
      }
      .info {
        color: #aaa;
        margin: 0.8rem 0;
        .operate {
          > div {
            margin-left: 0.8rem;
            img {
              width: 1.2rem;
              height: 1.2rem;
              vertical-align: middle;
            }
          }
        }
      }
    } // the end of content
  }
  // vant组件
  /deep/ .van-loading {
    text-align: center;
  }
}
</style>
