import Vue from "vue";
import {
  Loading,
  Rate,
  Popup,
  DatetimePicker,
  Uploader,
  ImagePreview,
  Dialog,
  Search,
  Swipe,
  SwipeItem,
  Tab,
  Tabs,
  Tag,
  Icon,
  Lazyload,
  Sidebar,
  SidebarItem,
  Button,
  Grid,
  GridItem,
  Cell,
  DropdownMenu,
  DropdownItem,
  Divider,
  Col,
  Row,
  Form,
  Field,
  Radio,
  RadioGroup,
  IndexBar,
  IndexAnchor,
  Area,
  Overlay,
  NoticeBar,
  CellGroup,
  Pagination,
  TreeSelect,
  Picker,
  Image,
  Empty,
  Checkbox,
} from "vant";
import VueWechatTitle from "vue-wechat-title";
import uweb from "vue-uweb";
import App from "@/page/App.vue";
import router from "@/route/route";
import store from "@/store/store";
import i18n from "@/i18n/i18n";
import VConsole from "vconsole";
import { sync } from "vuex-router-sync";
import filterApi from "@/util/filter";

Vue.use(Loading)
  .use(Popup)
  .use(DatetimePicker)
  .use(VueWechatTitle)
  .use(Rate)
  .use(Uploader)
  .use(ImagePreview)
  .use(Dialog)
  .use(Search)
  .use(Swipe)
  .use(SwipeItem)
  .use(Tab)
  .use(Tabs)
  .use(Tag)
  .use(Icon)
  .use(Lazyload)
  .use(Sidebar)
  .use(SidebarItem)
  .use(Button)
  .use(Grid)
  .use(GridItem)
  .use(Cell)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Divider)
  .use(Col)
  .use(Row)
  .use(Form)
  .use(Field)
  .use(Radio)
  .use(RadioGroup)
  .use(IndexBar)
  .use(IndexAnchor)
  .use(Area)
  .use(Overlay)
  .use(NoticeBar)
  .use(CellGroup)
  .use(Pagination)
  .use(TreeSelect)
  .use(Picker)
  .use(Image)
  .use(Empty)
  .use(Checkbox);

if (__ENV__ === "qa" || __ENV__ === "prod") {
  Vue.config.productionTip = false; // 关闭不必要的提示
  Vue.use(uweb, "1279411466");
} else {
  Vue.config.performance = true; // 开启性能追踪
  Vue.config.productionTip = true; // 开启更多提示
  Vue.use(new VConsole()); // 开启虚拟控制台
  Vue.use(uweb, "1279392047");
}
sync(store, router);
// 启用强制刷新
// Vue.forceUpdate();

// 全局过滤器
Object.keys(filterApi).forEach((key) => {
  Vue.filter(key, filterApi[key]);
});

const vue = new Vue({
  i18n,
  router: router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export default vue;
