<template>
  <div class="search_result_empty flex flex-center flex-middle flex-column">
    <!-- <img src="@/assets/images/icon/icon-empty-data.png" alt="暂无数据" />
    <p>暂无数据</p> -->
    <van-empty
      class="custom_image"
      :image="require('@/assets/images/icon/icon-empty-data.png')"
      description="暂无数据"
    />
  </div>
</template>

<script>
export default {};
</script>
<style lang="less" scoped>
.search_result_empty {
  flex-grow: 1;
  // img {
  //   width: 6rem;
  //   height: 6rem;
  // }
  // p {
  //   color: #aaa;
  // }
  .custom_image /deep/ .van-empty__image {
    width: 6rem;
    height: 6rem;
  }
}
</style>
