import Vue from "vue";
import VueRouter from "vue-router";
import sdkApi from "@/util/wxShare.js";
import store from "@/store/store";
// 1.为了达到路由懒加载的效果, 引用组件需要使用以下方式
// const Other = () => import("./other/index/index.vue");
// 2.首屏需要加载的组件直接引入
// import Home from "./home/index.vue";

import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      component: Home,
      meta: { title: "意米家 Foresee Find", allowBack: false, keepAlive: true },
    },
    {
      path: "/book",
      component: () => import("@/views/Book.vue"),
      meta: {
        title: "进口家具",
        allowBack: false,
      },
    },
    {
      path: "/user",
      component: () => import("@/views/User.vue"),
      meta: {
        title: "寒舍",
        allowBack: false,
      },
    },
    {
      path: "/user_nologin",
      component: () => import("@/views/UserNoLogin.vue"),
      meta: {
        title: "寒舍",
      },
    },
    {
      path: "/bind_phone",
      component: () => import("@/views/BindPhone.vue"),
      meta: {
        title: "绑定手机号",
      },
    },
    {
      path: "/search_entry",
      component: () => import("@/views/Search/SearchEntry.vue"),
      meta: { title: "意米家 Foresee Find" },
    },
    {
      path: "/search_result",
      query: {
        keyword: "",
      },
      component: () => import("@/views/Search/SearchResult.vue"),
      meta: { title: "意米家 Foresee Find" },
    },
    {
      path: "/video_detail",
      query: {
        id: 0,
      },
      component: () => import("@/views/Video/VideoDetails.vue"),
      meta: { title: "意米家 Foresee Find" },
    },
    {
      path: "/image_text_detail",
      component: () => import("@/views/DetailPage/ImageTextDetail.vue"),
      meta: { title: "意米家 Foresee Find" },
    },
    {
      path: "/international_list",
      component: () => import("@/views/International/InternationalList.vue"),
      meta: { title: "国际趋势" },
    },
    {
      path: "/international",
      component: () => import("@/views/International/International.vue"),
      meta: { title: "国际趋势" },
    },
    {
      path: "/international/more_brands",
      component: () => import("@/views/International/MoreBrand.vue"),
      meta: { title: "趋势主题" },
    },
    {
      path: "/study_tour_list",
      component: () => import("@/views/International/StudyTourList.vue"),
      meta: { title: "国际展览" },
    },
    {
      path: "/study_tour_detail",
      component: () => import("@/views/International/StudyTourDetail.vue"),
      meta: { title: "国际展览" },
    },
    {
      path: "/study_tour_detail/guide",
      component: () => import("@/views/International/StudyTourDetailGuide.vue"),
      meta: { title: "观展指南" },
    },
    {
      path: "/study_tour_reserve",
      component: () => import("@/views/International/StudyTourReserve.vue"),
      meta: { title: "预约" },
    },
    {
      path: "/study_tour_reserve_status",
      component: () => import("@/views/International/StudyTourReserveStatus.vue"),
      meta: { title: "预约审核" },
    },
    {
      path: "/designer",
      component: () => import("@/views/Design/Designer.vue"),
      meta: { title: "设计师" },
    },
    {
      path: "/designer_detail/:id",
      component: () => import("@/views/Design/DesignerDetail.vue"),
      meta: { title: "设计师" },
    },
    {
      path: "/all_content_list",
      component: () => import("@/views/Design/AllContentList.vue"),
      meta: { title: "相关产品和视频" },
    },
    {
      path: "/designer_works/:id",
      component: () => import("@/views/Design/DesignerWorks.vue"),
      meta: { title: "热门作品" },
    },
    {
      path: "/all_latest_picks_list",
      component: () => import("@/views/HomeRecommendList/AllLatestPicksList.vue"),
      meta: { title: "最新精选" },
    },
    {
      path: "/all_quality_topics_list",
      component: () => import("@/views/HomeRecommendList/AllQualityTopicsList.vue"),
      meta: { title: "设计风格" },
    },
    {
      path: "/quality_topics_detail",
      component: () => import("@/views/DetailPage/QualityTopicsDetail.vue"),
      meta: { title: "专题详情" },
    },
    {
      path: "/all_works_case",
      component: () => import("@/views/HomeRecommendList/AllWorksCaseList.vue"),
      meta: { title: "专案" },
    },
    {
      path: "/works_case_detail",
      component: () => import("@/views/DetailPage/WorksCaseDetail.vue"),
      meta: { title: "专案详情" },
    },
    {
      path: "/imported_brand",
      name: "ImportedBrand",
      component: () => import("@/views/Brand/ImportedBrand.vue"),
      meta: { title: "进口品牌", keepAlive: true },
    },
    {
      path: "/brand_home/:id",
      component: () => import("@/views/Brand/BrandHome.vue"),
      meta: { title: "品牌主页" },
    },
    {
      path: "/product_list",
      component: () => import("@/views/Product/ProductList.vue"),
      meta: { title: "产品清单" },
    },
    {
      path: "/product_directory",
      component: () => import("@/views/Product/ProductDirectory.vue"),
      meta: { title: "产品目录" },
    },
    {
      path: "/product_directory_detail",
      component: () => import("@/views/Product/ProductDirectoryDetail.vue"),
      meta: { title: "目录详情" },
    },
    {
      path: "/all_product/:id",
      component: () => import("@/views/Product/AllProduct.vue"),
      meta: { title: "全部产品" },
    },
    {
      path: "/product_detail",
      component: () => import("@/views/Product/ProductDetail.vue"),
      meta: { title: "产品详情" },
    },
    {
      path: "/product_param",
      component: () => import("@/views/Product/ProductParam.vue"),
      meta: { title: "技术参数" },
    },
    {
      path: "/product_consult",
      component: () => import("@/views/Product/ProductConsult.vue"),
      meta: { title: "产品咨询" },
    },
    {
      path: "/brand_consult",
      component: () => import("@/views/Brand/BrandConsult.vue"),
      meta: { title: "品牌咨询" },
    },
    {
      path: "/select_category",
      component: () => import("@/views/Furnish/SelectCategory.vue"),
      meta: { title: "产品分类" },
    },
    {
      path: "/production_classify/:keyword",
      component: () => import("@/views/Furnish/ProductionClassify.vue"),
      meta: { title: "" },
    },
    {
      path: "/personal_info",
      component: () => import("@/views/Personal/PersonalInfo.vue"),
      meta: { title: "个人资料" },
    },
    {
      path: "/collection",
      component: () => import("@/views/Collection/Collection.vue"),
      meta: { title: "收藏" },
    },
    {
      path: "/follow",
      component: () => import("@/views/Follow/Follow.vue"),
      meta: { title: "关注列表" },
    },
    {
      path: "/fan",
      component: () => import("@/views/Personal/Fan.vue"),
      meta: { title: "粉丝" },
    },
    {
      path: "/my_home_page",
      component: () => import("@/views/MyHomePage.vue"),
      meta: { title: "我的主页" },
    },
    {
      path: "/my_hot_works",
      component: () => import("@/views/MyHotWorks.vue"),
      meta: { title: "作品集" },
    },
    {
      path: "/edit_my_info",
      component: () => import("@/views/EditMyInfo.vue"),
      meta: { title: "编辑资料" },
    },
    {
      path: "/my_invitation_card",
      component: () => import("@/views/InvitationCard/MyInvitationCard.vue"),
      meta: { title: "我的邀请函" },
    },
    {
      path: "/contribution",
      component: () => import("@/views/Contribution.vue"),
      meta: { title: "投稿" },
    },
    {
      path: "/certification_center",
      component: () => import("@/views/Certification/CertificationCenter.vue"),
      meta: { title: "认证中心" },
    },
    {
      path: "/brand_certification",
      component: () => import("@/views/Certification/BrandCertifications.vue"),
      meta: { title: "申请认证" },
    },
    {
      path: "/brand_protocol",
      component: () => import("@/views/Certification/BrandProtocol.vue"),
      meta: { title: "意米家平台品牌入驻协议" },
    },
    {
      path: "/media_certification",
      component: () => import("@/views/Certification/MediaCertifications.vue"),
      meta: { title: "申请认证" },
    },
    {
      path: "/design_certification",
      component: () => import("@/views/Certification/DesignCertification.vue"),
      meta: { title: "申请认证" },
    },
    {
      path: "/brand_certification_audit",
      component: () => import("@/views/Certification/BrandCertificationAudit.vue"),
      meta: { title: "申请认证" },
    },
    {
      path: "/media_certification_audit",
      component: () => import("@/views/Certification/MediaCertificationAudit.vue"),
      meta: { title: "申请认证" },
    },
    {
      path: "/designer_certification_audit",
      component: () => import("@/views/Certification/DesignerCertificationAudit.vue"),
      meta: { title: "认证审核" },
    },
    {
      path: "/organization_certification_audit",
      component: () => import("@/views/Certification/OrganizationCertificationAudit.vue"),
      meta: { title: "认证审核" },
    },
    {
      path: "/consultation_feedback",
      component: () => import("@/views/ConsultationFeedback.vue"),
      meta: { title: "咨询与反馈" },
    },
    {
      path: "/virtual_reality",
      component: () => import("@/views/VirtualReality.vue"),
      meta: { title: "" },
    },
  ],
});

router.afterEach((to, from) => {
  // document.body.scrollTop = 0;
  // document.documentElement.scrollTop = 0;
  // document.querySelector(".router_view").scrollTop = store.state.scrollTop;
  const link = window.location.href.split("#")[0] + "#" + to.fullPath;
  store.state.currentPage =
    link.indexOf("image_text_detail") > -1
      ? "图文详情页面"
      : link.indexOf("video_detail") > -1
      ? "视频详情页面"
      : link.indexOf("works_case_detail") > -1
      ? "专案详情页面"
      : link.indexOf("product_detail") > -1
      ? "产品详情页面"
      : link.indexOf("product_directory_detail") > -1
      ? "产品目录详情页面"
      : to.meta.title + "页面";
  if (to.query.fromShare) {
    store.state.fullPath = to.fullPath;
  } else {
    if (to.fullPath.indexOf("?") > 0) {
      store.state.fullPath = to.fullPath + "&fromShare=true";
    } else {
      store.state.fullPath = to.fullPath + "?fromShare=true";
    }
  }
  if (
    !(
      /^\/international/.test(to.path) ||
      /^\/study_tour_detail/.test(to.path) ||
      /^\/brand_home/.test(to.path) ||
      /^\/designer_detail/.test(to.path) ||
      /^\/my_home_page/.test(to.path) ||
      /^\/image_text_detail/.test(to.path) ||
      /^\/video_detail/.test(to.path) ||
      /^\/works_case_detail/.test(to.path) ||
      /^\/product_detail/.test(to.path) ||
      /^\/virtual_reality/.test(to.path)
    )
  ) {
    // 调用微信分享接口
    sdkApi.getJSSDK({
      title: "意米家 Foresee Find",
      desc: "看设计  看品牌  看世界",
      link: window.location.href.split("#")[0] + `?ts=${Date.now()}#` + store.state.fullPath,
      imgUrl: "http://oss-benma-test.benma-code.com/vl/img/xHfM1mSlOmtFCqEsJMuwMMIxtW68odgH.png",
    });
  }
});
router.beforeEach((to, from, next) => {
  if (to.query.fromShare && JSON.parse(to.query.fromShare)) {
    store.state.showHomeIcon = true;
  } else {
    store.state.showHomeIcon = false;
  }
  /* 路由发生变化修改页面meta */
  if (to.meta.content) {
    const head = document.getElementsByTagName("head");
    const meta = document.createElement("meta");
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }
  /* 路由发生变化修改页面title */
  if (/^\/product_list/.test(to.path)) {
    to.meta.title = to.query.title;
    document.title = to.meta.title;
  } else if (/^\/production_classify/.test(to.path)) {
    to.meta.title = to.params.keyword;
    document.title = to.meta.title;
  } else if (/^\/virtual_reality/.test(to.path)) {
    to.meta.title = to.query.name;
    document.title = to.meta.title;
  } else if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
// 路由后退
// window.addEventListener("popstate", (e) => {
//   window.history.go(window.location.href.split("#")[0] + "/user");
// });
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
