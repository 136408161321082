import { ResultCodeEnum, myCenterApi, contentSourcesApi } from "@/api/index.js";
import sdkApi from "@/util/wxShare.js";
export default {
  // 根据id获取对应设计师
  async getDesignerById({ rootState, state }, id) {
    const res = await contentSourcesApi.designerById(id);
    if (res.code === ResultCodeEnum.success.code) {
      state.designerDetail = res.data;
      document.title = res.data.name;
      if (rootState.route.query.fromShare) {
        rootState.fullPath = rootState.route.fullPath;
      } else {
        if (rootState.route.fullPath.indexOf("?") > 0) {
          rootState.fullPath = rootState.route.fullPath + "&fromShare=true";
        } else {
          rootState.fullPath = rootState.route.fullPath + "?fromShare=true";
        }
      }
      // 调用微信分享接口
      sdkApi.getJSSDK({
        title: "意米家 Foresee Find",
        desc: state.designerDetail.name,
        link: window.location.href.split("#")[0] + `?ts=${Date.now()}#` + rootState.fullPath,
        imgUrl: "http://oss-benma-test.benma-code.com/vl/img/xHfM1mSlOmtFCqEsJMuwMMIxtW68odgH.png",
      });
    }
  },
  // 获取热门作品
  async getHotWorksList({ state }, params) {
    const res = await myCenterApi.hotContentList(params);
    if (res.code === ResultCodeEnum.success.code) {
      state.designerHotWorks = res.data.rows;
      state.designerHotWorksPageInfo = res.data.pageInfo;
    }
  },
};
