import mutations from "@/store/module/search/mutation.js";
import actions from "@/store/module/search/action.js";

export default {
  namespaced: true,
  state: {
    // 搜索部分
    // 近期搜索列表
    recentSearchList: [],
    // 搜索框的值
    searchValue: "",
    // 搜索参数
    searchQuery: {
      pageNum: 0,
      pageSize: 10,
      keyword: "",
      searchType: "production",
    },
    // 搜索结果
    searchResultList: [],
    searchResultPageInfo: {},
  },
  mutations,
  actions,
};
