var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-column",attrs:{"id":"app"}},[_c('div',{staticClass:"router_view flex flex-column"},[_c('keep-alive',[(_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1),_vm._v(" "),(!_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e(),_vm._v(" "),(
        !(/^\/home/.test(_vm.currentPath) || /^\/imported_brand/.test(_vm.currentPath) || /^\/user/.test(_vm.currentPath)) &&
          _vm.$store.state.showHomeIcon
      )?_c('router-link',{attrs:{"to":"/home"}},[_c('div',{staticClass:"home_icon flex flex-center flex-middle"},[_c('img',{attrs:{"src":require("@/assets/images/icon/home_icon.png"),"alt":"首页图标"}})])]):_vm._e()],1),_vm._v(" "),_c('div',{class:/^\/home/.test(_vm.currentPath) || /^\/imported_brand/.test(_vm.currentPath) || /^\/user/.test(_vm.currentPath)
        ? 'menuBar'
        : 'menuBar menuBar_hide'},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(/^\/home/.test(_vm.currentPath) || /^\/imported_brand/.test(_vm.currentPath) || /^\/user/.test(_vm.currentPath)),expression:"/^\\/home/.test(currentPath) || /^\\/imported_brand/.test(currentPath) || /^\\/user/.test(currentPath)"}],staticClass:"menu flex"},[_c('div',{staticClass:"menuItem flex flex-column flex-middle",on:{"click":function($event){return _vm.changePath('/home')}}},[_c('img',{staticClass:"iconTabbar",attrs:{"src":/^\/home/.test(_vm.currentPath)
              ? require('@/assets/images/icon/tab-index1.png')
              : require('@/assets/images/icon/tab-index2.png'),"alt":""}}),_vm._v(" "),_c('div',{staticClass:"menuText",style:({ color: /^\/home/.test(_vm.currentPath) ? '#000' : '#999' })},[_vm._v("YIMI")])]),_vm._v(" "),_c('div',{staticClass:"menuItem flex flex-column flex-middle",on:{"click":function($event){return _vm.changePath('/imported_brand')}}},[_c('img',{staticClass:"iconTabbar",attrs:{"src":/^\/imported_brand/.test(_vm.currentPath)
              ? require('@/assets/images/icon/tab-furniture1.png')
              : require('@/assets/images/icon/tab-furniture2.png'),"alt":""}}),_vm._v(" "),_c('div',{staticClass:"menuText",style:({ color: /^\/imported_brand/.test(_vm.currentPath) ? '#000' : '#999' })},[_vm._v("\n          进口品牌\n        ")])]),_vm._v(" "),_c('div',{staticClass:"menuItem flex flex-column flex-middle",on:{"click":_vm.showUserPage}},[_c('img',{staticClass:"iconTabbar",attrs:{"src":/^\/user/.test(_vm.currentPath)
              ? require('@/assets/images/icon/tab-mine1.png')
              : require('@/assets/images/icon/tab-mine2.png'),"alt":""}}),_vm._v(" "),_c('div',{staticClass:"menuText",style:({ color: /^\/user/.test(_vm.currentPath) ? '#000' : '#999' })},[_vm._v("寒舍")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }