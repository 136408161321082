<template>
  <div class="container">
    <!-- 内容区域 -->
    <div ref="main" class="main">
      <!-- 搜索栏 -->
      <SearchBox></SearchBox>
      <!-- 导航栏 -->
      <div class="scroll_nav">
        <van-tabs key="home" v-model="active" :before-change="beforeChange">
          <van-tab
            v-for="(item, index) in navList"
            :key="index"
            :title-style="
              item.name.length > 2 ? (item.name.length > 3 ? 'flex-basis:28%' : 'flex-basis:24%') : 'flex-basis:20%'
            "
            :title="item.name"
          ></van-tab>
        </van-tabs>
      </div>
      <div v-show="JSON.stringify(currentItem) !== '{}'">
        <!-- <router-view></router-view> -->
        <div v-if="active === 0">
          <Recommend></Recommend>
        </div>
        <div v-else-if="currentItem.type === 'video'">
          <Video :channel-id="currentItem.id"></Video>
        </div>
        <div v-else-if="currentItem.type === 'inspiration'">
          <Inspiration ref="inspiration" :channel-id="currentItem.id"></Inspiration>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBox from "@/components/SearchBox.vue";
import Recommend from "@/views/Recommend.vue";
import Video from "@/views/Video/Video.vue";
import Inspiration from "@/views/Inspiration/Inspiration.vue";
import { ResultCodeEnum, channelApi, loginApi } from "@/api/index";
import { Toast } from "vant";
export default {
  components: {
    SearchBox,
    Recommend,
    Video,
    Inspiration,
  },
  data() {
    return {
      query: {
        pageNum: 0,
        pageSize: 100,
      },
      navList: [],
      active: 0,
    };
  },
  computed: {
    currentItem() {
      return this.navList.find((item, index) => index === this.active);
    },
  },
  mounted() {
    this.getChannelList();
    // 登录
    console.log(__ENV__);
    if (__ENV__ === "local" || __ENV__ === "test") {
      this.verifyWebLogin();
    } else {
      this.verifyLogin();
    }
  },
  methods: {
    // tab切换前执行的操作
    beforeChange(index) {
      // 返回 false 表示阻止此次切换
      if (this.currentItem.type === "inspiration" && JSON.stringify(this.$refs.inspiration.pageInfo) === "{}") {
        Toast("您操作的太快了");
        return false;
      } else {
        return true;
      }
    },
    // 判断是否登录
    async verifyWebLogin() {
      const res = await loginApi.web.verifyLogin();
      if (res.code === ResultCodeEnum.unauthenticated.code) {
        // TODO：发布测试环境要注释掉
        this.onSimulationLogin();
      } else if (res.code === ResultCodeEnum.success.code) {
        this.$store.dispatch("getLoginUserInfo"); // 获取当前用户信息
        this.$store.dispatch("getMyAuthentication"); // 获取当前用户认证身份信息
        // 获取会员详情
        this.$store.dispatch("getVip");
      }
    },
    // 模拟登录
    async onSimulationLogin() {
      const res = await loginApi.web.login({
        loginName: "13609748850",
        password: "123456",
      });
      if (res.code === ResultCodeEnum.success.code) {
        // 请求成功后处理逻辑
        this.$store.dispatch("getLoginUserInfo"); // 获取当前用户信息
        this.$store.dispatch("getMyAuthentication"); // 获取当前用户认证身份信息
        // 获取会员详情
        this.$store.dispatch("getVip");
      }
    },
    // 微信公众号登录
    async verifyLogin() {
      const res = await loginApi.wxmp.verifyLogin({
        refererUrl: location.href.split("#")[1],
      });
      if (res.code === ResultCodeEnum.success.code) {
        // 请求成功后处理逻辑
        this.$store.dispatch("getLoginUserInfo"); // 获取当前用户信息
        this.$store.dispatch("getMyAuthentication"); // 获取当前用户认证身份信息
      } else {
        // 请求失败 个人中心显示未登录页面
      }
    },
    // 获取频道
    async getChannelList() {
      const res = await channelApi.channel(this.query);
      if (res.code === ResultCodeEnum.success.code) {
        res.data.rows.forEach((item) => {
          if (item.sourceType.trim() === "视频") {
            item.type = "video";
          } else {
            item.type = "inspiration";
          }
        });
        this.navList = res.data.rows;
        // 添加 推荐 这个频道
        this.navList.unshift({
          name: "推荐",
          type: "recommend",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.home_container {
  .scroll_nav {
    .van-tabs__line {
      background-color: #fff !important;
    }
  }
}
.menus {
  padding: 1.4rem 0.5rem;
  .menuItem {
    width: 33.33%;
    text-align: center;
    border-bottom: 1px solid #dedede;
    border-right: 1px solid #dedede;
    padding: 1.4rem;
    &:first-child,
    &:nth-child(2),
    &:nth-child(3) {
      padding-top: 0;
    }
    &:nth-child(3),
    &:nth-child(6),
    &:nth-child(9) {
      border-right: none;
    }
    &:nth-child(7),
    &:nth-child(8) {
      padding-bottom: 0;
      border-bottom: none;
    }
    .iconMenu {
      width: 2.5rem;
      height: 2.5rem;
    }
    .menuText {
      margin-top: 0.25rem;
      font-weight: bold;
    }
  }
}
</style>
