import request from "@/util/request";
const webUrlPrefix = "v1/login/member/web";
const wxmpUrlPrefix = "v1/login/member/wxmp";

export default {
  // 门户登录
  web: {
    login: (params) => {
      return request.post(`${webUrlPrefix}/login`, params);
    },
    logout: () => {
      return request.post(`${webUrlPrefix}/logout`);
    },
    verifyLogin: () => {
      return request.get(`${webUrlPrefix}/verify_login`);
    },
  },
  // 微信公众号登录
  wxmp: {
    verifyLogin: (params) => {
      return request.post(`${wxmpUrlPrefix}/verify_login`, params);
    },
    logout: () => {
      return request.post(`${wxmpUrlPrefix}/logout`);
    },
  },
};
