export default {
  // 缓存用户的搜索记录
  cacheUserSearchHistory(state, value) {
    if (state.recentSearchList.length === 0) {
      state.recentSearchList.push(value);
    } else {
      // 判断搜索值是否已存在
      const flag = state.recentSearchList.some((item) => {
        if (item === value) {
          return true;
        }
      });
      // 不存在
      if (!flag) {
        // 将搜索框提交的值插到到数组【首端】中
        state.recentSearchList.unshift(value);

        // 将新数组缓存到本地存储中
        window.localStorage.setItem("recentSearchList", JSON.stringify(state.recentSearchList.slice(0, 10)));
      }
    }
  },
};
