import { ResultCodeEnum, brandApi } from "@/api/index.js";
import sdkApi from "@/util/wxShare.js";
// const openVIP = (dispatch) => {
//   // dispatch("vip/joinVip", { vipRankCode: "VIP1", remark: "" });
//   console.log(dispatch);
// };
export default {
  // 品牌
  async getBrandList({ state }, params) {
    const res = await brandApi.brandsAll(params);
    if (res.code === ResultCodeEnum.success.code) {
      state.brandList = res.data;
    }
  },
  // 根据id获取品牌
  async getBrandById({ state, rootState, dispatch }, payload) {
    const res = await brandApi.brandsById(payload.id, payload.params);
    if (res.code === ResultCodeEnum.success.code) {
      state.brandHome = res.data;
      document.title = res.data.name;
      if (rootState.route.query.fromShare) {
        rootState.fullPath = rootState.route.fullPath;
      } else {
        if (rootState.route.fullPath.indexOf("?") > 0) {
          rootState.fullPath = rootState.route.fullPath + "&fromShare=true";
        } else {
          rootState.fullPath = rootState.route.fullPath + "?fromShare=true";
        }
      }
      // 调用微信分享接口
      sdkApi.getJSSDK({
        title: "意米家 Foresee Find",
        desc: state.brandHome.name,
        link: window.location.href.split("#")[0] + `?ts=${Date.now()}#` + rootState.fullPath,
        imgUrl: "http://oss-benma-test.benma-code.com/vl/img/xHfM1mSlOmtFCqEsJMuwMMIxtW68odgH.png",
      });
    }
  },
};
