import request from "@/util/request";
const brandPrefix = "/v1/member/brands";

export default {
  brandsAll(params) {
    return request.get(`${brandPrefix}/all`, params);
  },
  countriesAndRegionsForBrand(params) {
    return request.get(`${brandPrefix}/countriesAndRegionsForBrand`, params);
  },
  brandProductionCatalogList(params) {
    return request.get(`${brandPrefix}/brandProductionCatalogList`, params);
  },
  getBrandProductionCatalog(id) {
    return request.get(`${brandPrefix}/getBrandProductionCatalog/${id}`);
  },
  brandsById(id, params) {
    return request.get(`${brandPrefix}/${id}`, params);
  },
  exhibitionList(params) {
    return request.get(`${brandPrefix}/exhibitionList`, params);
  },
  getExhibitionById(id) {
    return request.get(`${brandPrefix}/getExhibition/${id}`);
  },
};
