import actions from "@/store/module/list/action.js";

export default {
  namespaced: true,
  state: {
    // 最新精选列表
    latestPicksList: [],
    // 根据id获取对应最新精选
    latestPicks: {},
    // 最新精选的信息
    latestPicksPageInfo: {},
    // 设计风格列表
    preferredTopicsList: [],
    // 根据id获取对应设计风格
    preferredTopics: {},
    // 设计风格的信息
    preferredTopicsPageInfo: {},
    // 作品案例列表
    worksCaseList: [],
    // 根据id获取对应作品案例详细信息
    worksCase: {},
    // 作品案例的信息
    worksCasePageInfo: {},
  },
  actions,
};
