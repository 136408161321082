import Vue from "vue";
import VueX from "vuex";

import mutations from "@/store/mutations.js";
import actions from "@/store/actions.js";
import search from "@/store/module/search";
import list from "@/store/module/list";
import comment from "@/store/module/comment";
import product from "@/store/module/product";
import brand from "@/store/module/brand";
import vip from "@/store/module/vip";
import designer from "@/store/module/designer";

Vue.use(VueX);

const store = new VueX.Store({
  state: {
    // 上传的文件的链接
    fileUrl: "",
    // 视频部分
    // 视频参数
    // videoQuery: {
    //   pageNum: 0,
    //   pageSize: 5,
    //   keyword: "",
    // },
    // 图文列表
    imageTextList: [],
    // 图文详情
    imageTextDetail: {},
    // 视频列表
    videoList: [],
    // 视频详情
    videoDetail: {},
    // 根据id获取对应视频
    videos: {},

    // 个人中心当前用户信息
    loginUser: {},
    // 控制（分享提示）遮挡层的显示与隐藏
    showOverlay: false,
    // 是否收藏标识
    collectOrNot: false,
    // 所有字典值列表
    dictionaryList: [],
    // 字典值--擅长领域
    dictionaryListAboutField: [],
    // 字典值--国家
    dictionaryListAboutCountry: [],
    // 获取所有省份+城市
    provincesAndCitiesList: [],
    // 我的认证信息
    myAuthentication: {},
    // 会员详情
    vipDetail: {},
    // 内核
    userAgent: "",
    // 当前分享页面
    currentPage: "",
    // 绑定手机号码成功标识
    bindSuccessFlag: false,
    // 显示首页图标
    showHomeIcon: false,
    fullPath: "",
    scrollTop: 0,
  },
  mutations,
  actions,
  modules: {
    search,
    list,
    comment,
    product,
    brand,
    vip,
    designer,
  },
});

export default store;
