<template>
  <div class="share_tips_container">
    <!-- 遮挡层指示 -->
    <van-overlay :show="$store.state.showOverlay" :lock-scroll="false" @click="$store.state.showOverlay = false">
      <div class="share_tip flex flex-column flex-center flex-middle">
        <img src="@/assets/images/icon/share_tip.png" alt="分享提示" />
        <span>点击右上角分享</span>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.share_tips_container {
  .share_tip {
    position: absolute;
    top: 1rem;
    right: 10%;
    > span {
      margin-top: 0.4rem;
      color: #fff;
    }
  }
}
</style>
