import { contentCommentApi, ResultCodeEnum } from "@/api/index.js";
import { Toast } from "vant";

export default {
  // 获取评论详情
  async getContentCommentsList({ state }, payload) {
    // const pageInfo = { pageNum: 0, pageSize: 5 };
    // const params = Object.assign(pageInfo, payload);
    const res = await contentCommentApi.contentComment(payload);
    if (res.code === ResultCodeEnum.success.code) {
      state.contentCommentList.push(...res.data.rows);
      state.contentCommentPageInfo = res.data.pageInfo;
    }
  },
  // 提交评论内容
  async postContentComment({ state, dispatch }, payload) {
    const res = await contentCommentApi.addContentComment(payload);
    if (res.code === ResultCodeEnum.success.code) {
      // 更新评论内容
      state.contentCommentList = [];
      dispatch("getContentCommentsList", {
        pageNum: 0,
        pageSize: 5,
        contentId: payload.contentId,
        contentType: payload.contentType,
      });
      Toast("提交评论成功~");
    }
  },
  // 提交回复
  async postReplyContentComment({ state, dispatch }, payload) {
    const res = await contentCommentApi.replyComment(payload);
    if (res.code === ResultCodeEnum.success.code) {
      // 更新评论内容
      state.contentCommentList = [];
      dispatch("getContentCommentsList", {
        pageNum: 0,
        pageSize: 5,
        contentId: payload.contentId,
        contentType: payload.contentType,
      });
      Toast("回复评论成功~");
    }
  },
  // 删除评论
  async deleteContentCommentById({ state, dispatch }, payload) {
    const res = await contentCommentApi.deleteContentCommentById(payload.ids);
    if (res.code === ResultCodeEnum.success.code) {
      // 更新评论内容
      state.contentCommentList = [];
      dispatch("getContentCommentsList", {
        pageNum: 0,
        pageSize: 5,
        contentId: payload.contentId,
        contentType: payload.contentType,
      });
      Toast("删除评论成功~");
    }
  },
};
