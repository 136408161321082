import { ResultCodeEnum, jssdkApi } from "@/api/index";
// import { Toast } from "vant";
import vm from "@/page/index/index";
import { data } from "autoprefixer";
const jsApiList = [
  "updateAppMessageShareData",
  "updateTimelineShareData",
  "onMenuShareQQ",
  "onMenuShareAppMessage",
  "onMenuShareTimeline",
  "onMenuShareQZone",
];
const wx = window.wx;
// 要用到微信API
async function getJSSDK(dataForWeixin) {
  // 调用后台接口换取参数
  const res = await jssdkApi.getJSSDK({ url: location.href.split("#")[0] });

  if (res.code === ResultCodeEnum.success.code) {
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: res.data.appId, // 必填，公众号的唯一标识
      timestamp: res.data.timestamp, // 必填，生成签名的时间戳
      nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
      signature: res.data.signature, // 必填，签名
      jsApiList: jsApiList, // 必填，需要使用的JS接口列表
    });
    // 需在用户可能点击分享按钮前就先调用
    wx.ready(() => {
      let content = "";
      if (
        !(
          /^\/international/.test(vm.$store.state.route.path) ||
          /^\/study_tour_detail/.test(vm.$store.state.route.path) ||
          /^\/brand_home/.test(vm.$store.state.route.path) ||
          /^\/designer_detail/.test(vm.$store.state.route.path) ||
          /^\/my_home_page/.test(vm.$store.state.route.path) ||
          /^\/image_text_detail/.test(vm.$store.state.route.path) ||
          /^\/video_detail/.test(vm.$store.state.route.path) ||
          /^\/works_case_detail/.test(vm.$store.state.route.path) ||
          /^\/product_detail/.test(vm.$store.state.route.path) ||
          /^\/virtual_reality/.test(vm.$store.state.route.path)
        )
      ) {
        content = dataForWeixin.title;
      } else {
        content = dataForWeixin.desc;
      }
      // 兼容问题
      // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
      if (wx.updateAppMessageShareData) {
        wx.updateAppMessageShareData({
          title: dataForWeixin.title, // 分享标题
          desc: dataForWeixin.desc, // 分享描述
          link: dataForWeixin.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: dataForWeixin.imgUrl, // 分享图标
          success: function() {
            // 设置成功
            // Toast("分享朋友/QQ成功");
            vm.$uweb.trackEvent("分享图标", "分享", `点击${vm.$store.state.currentPage}的分享图标，分享朋友/QQ成功`);
          },
        });
      } else {
        // 分享给朋友
        wx.onMenuShareAppMessage({
          title: dataForWeixin.title, // 分享标题
          desc: dataForWeixin.desc, // 分享描述
          link: dataForWeixin.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: dataForWeixin.imgUrl, // 分享图标
          success: function() {
            // 设置成功
            // Toast("分享朋友成功");
            vm.$uweb.trackEvent("分享图标", "分享", `点击${vm.$store.state.currentPage}的分享图标，分享朋友成功`);
          },
          cancel: function() {
            // 用户取消分享后执行的回调函数
            // Toast("取消分享");
          },
        });
        // 分享到QQ
        wx.onMenuShareQQ({
          title: dataForWeixin.title, // 分享标题
          desc: dataForWeixin.desc, // 分享描述
          link: dataForWeixin.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: dataForWeixin.imgUrl, // 分享图标
          success: function() {
            // 设置成功
            // Toast("成功分享到QQ");
            vm.$uweb.trackEvent("分享图标", "分享", `点击${vm.$store.state.currentPage}的分享图标，成功分享QQ`);
          },
          cancel: function() {
            // 用户取消分享后执行的回调函数
            // Toast("取消分享到QQ");
          },
        });
      }
      // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
      if (wx.updateTimelineShareData) {
        wx.updateTimelineShareData({
          title: content, // 分享标题
          link: dataForWeixin.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: dataForWeixin.imgUrl, // 分享图标
          success: function() {
            // 设置成功
            // Toast("分享朋友圈/QQ空间成功");
            vm.$uweb.trackEvent(
              "分享图标",
              "分享",
              `点击${vm.$store.state.currentPage}的分享图标，分享朋友圈/QQ空间成功`
            );
          },
          cancel: function() {
            // 用户取消分享后执行的回调函数
            // Toast("取消分享");
          },
        });
      } else {
        // 分享到朋友圈
        wx.onMenuShareTimeline({
          title: content, // 分享标题
          link: dataForWeixin.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: dataForWeixin.imgUrl, // 分享图标
          success: function() {
            // 设置成功
            // Toast("成功分享到朋友圈");
            vm.$uweb.trackEvent("分享图标", "分享", `点击${vm.$store.state.currentPage}的分享图标，成功分享到朋友圈`);
          },
          cancel: function() {
            // 用户取消分享后执行的回调函数
            // Toast("取消分享");
          },
        }),
          // 分享到QQ空间
          wx.onMenuShareQZone({
            title: dataForWeixin.title, // 分享标题
            desc: dataForWeixin.desc, // 分享描述
            link: dataForWeixin.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: dataForWeixin.imgUrl, // 分享图标
            success: function() {
              // 设置成功
              // Toast("分享到QQ空间");
              vm.$uweb.trackEvent("分享图标", "分享", `点击${vm.$store.state.currentPage}的分享图标，成功分享到QQ空间`);
            },
            cancel: function() {
              // 用户取消分享后执行的回调函数
              // Toast("取消分享到QQ空间");
            },
          });
      }
    });

    wx.error((res) => {
      alert(JSON.stringify(res) + "微信验证失败");
    });
  }
}
export default {
  // 获取JSSDK
  getJSSDK: getJSSDK,
};
