import { productApi, ResultCodeEnum } from "@/api/index.js";
import sdkApi from "@/util/wxShare.js";
export default {
  // 获取产品
  async getProductList({ state }, payload) {
    const res = await productApi.production(payload);
    if (res.code === ResultCodeEnum.success.code) {
      state.productList.push(...res.data.rows);
      state.productPageInfo = res.data.pageInfo;
    }
  },
  // 根据id获取产品
  async getProductById({ rootState, state }, id) {
    const res = await productApi.productionById(id);
    if (res.code === ResultCodeEnum.success.code) {
      state.productDetail = res.data;
      document.title = res.data.name;
      if (rootState.route.query.fromShare) {
        rootState.fullPath = rootState.route.fullPath;
      } else {
        if (rootState.route.fullPath.indexOf("?") > 0) {
          rootState.fullPath = rootState.route.fullPath + "&fromShare=true";
        } else {
          rootState.fullPath = rootState.route.fullPath + "?fromShare=true";
        }
      }
      // 调用微信分享接口
      sdkApi.getJSSDK({
        title: "意米家 Foresee Find",
        desc: state.productDetail.name,
        link: window.location.href.split("#")[0] + `?ts=${Date.now()}#` + rootState.fullPath,
        imgUrl: "http://oss-benma-test.benma-code.com/vl/img/xHfM1mSlOmtFCqEsJMuwMMIxtW68odgH.png",
      });
    }
  },
};
