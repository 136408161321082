<template>
  <div class="search_box">
    <!-- TODO: 让搜索框有动画效果 -->
    <van-form action="javascript:void 0">
      <van-search
        v-model="searchValue"
        name="search"
        @blur="unClickSearchBox"
        @focus="focusSearchBox"
        @keypress.13="submit"
        @clear="clickClearButton"
      />
    </van-form>
    <div class="search_text flex flex-center flex-middle"><van-icon name="search" />&nbsp;搜索</div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {};
  },
  computed: {
    searchValue: {
      get() {
        return this.$store.state.search.searchValue;
      },
      set(val) {
        this.$store.state.search.searchValue = val;
      },
    },
  },
  mounted() {},
  methods: {
    // 点击聚焦搜索框
    focusSearchBox() {
      if (this.$route.path !== "/search_entry" && this.searchValue.trim() === "") {
        this.$router.push("/search_entry");
      }
      document.querySelector(".van-icon-search").setAttribute("data-icon", "\uF0AF");
      document.querySelector(".search_text").style.display = "none";
    },
    // 取消点击搜索框
    unClickSearchBox() {
      if (this.searchValue.trim() === "") {
        document.querySelector(".van-icon-search").setAttribute("data-icon", "");
        document.querySelector(".search_text").style.display = "inline-block";
        this.$router.push("/search_entry");
      }
    },
    clickClearButton() {
      this.searchValue = "";
      // this.$router.push("/search_entry");
    },
    submit() {
      if (this.searchValue.trim() === "") {
        Toast("请输入内容");
        this.$router.push("/search_entry");
        return;
      }
      // 缓存搜索记录
      this.$store.commit("search/cacheUserSearchHistory", this.searchValue.trim());
      // 调用接口前先清空列表,防止搜索新的关键词时页面还先显示上一次搜索结果
      this.$store.state.search.searchResultList.length = 0;
      // 跳转到搜索结果的产品分类页面
      this.$router.push(`/search_result?keyword=${this.searchValue.trim()}`);
    },
  },
};
</script>

<style lang="less" scoped>
.search_box {
  position: relative;
}
.search_box .search_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #bbb;
}
.search_box .search_text:active {
  display: none;
}
</style>
