export default {
  "en-US": {
    // 底部tab栏
    tab: {
      home: "Home",
      book: "Book",
      toy: "Toy",
      course: "Course",
    },
    // 提示语
    message: {
      hello: "hello, world",
    },
  },
};
