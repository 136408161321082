import request from "@/util/request";

const feedbackPrefix = "/v1/member/feedbacks";

export default {
  feedBack(params) {
    return request.get(`${feedbackPrefix}`, params);
  },
  postFeedBack(params) {
    return request.post(`${feedbackPrefix}`, params);
  },
  consultBrand(params) {
    return request.post(`${feedbackPrefix}/consultBrand`, params);
  },
  consultProduction(params) {
    return request.post(`${feedbackPrefix}/consultProduction`, params);
  },
  feedBackById(id) {
    return request.get(`${feedbackPrefix}/${id}`);
  },
};
