import request from "@/util/request.js";

const searchPrefix = "/v1/member/search";

export default {
  homeSearch(params) {
    return request.get(`${searchPrefix}/homeSearch`, params);
  },
  hotSearch() {
    return request.get(`${searchPrefix}/hotKeywordList`);
  },
};
