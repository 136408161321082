import request from "@/util/request";

const collectPrefix = "/v1/member/collects";

export default {
  deleteCollectById(id, params) {
    return request.delete(`${collectPrefix}/${id}`, params);
  },
  collectById(id, params) {
    return request.post(`${collectPrefix}/${id}`, params);
  },
  isCollected(params) {
    return request.get(`${collectPrefix}/isCollected`, params);
  },
};
