<template>
  <div class="recommend_container">
    <!-- 轮播图 -->
    <div class="carousel">
      <van-swipe :autoplay="2000">
        <van-swipe-item v-for="(item, index) in carouselList" :key="index" @click="enterSwipeItemDetailPage(item)">
          <!-- 显示标题 -->
          <div class="title">
            <p class="line2">{{ item.title }}</p>
          </div>
          <div class="coverImg">
            <Cover :src="item.img" alt="首页" height="60%"></Cover>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 选项列表 -->
    <div class="options flex flex-around flex-middle">
      <div class="brand" @click="enterImportedBrandPage">
        <div class="pics">
          <img v-show="icons.import_furniture_cover_img" :src="icons.import_furniture_cover_img" alt />
        </div>
        <div class="name">进口家具</div>
      </div>
      <div class="international" @click="enterInternationPage">
        <div class="pics">
          <img v-show="icons.international_trend_cover_img" :src="icons.international_trend_cover_img" alt />
        </div>
        <div class="name">国际趋势</div>
      </div>
      <div class="designer" @click="enterDesignPage">
        <div class="pics">
          <img v-show="icons.designer_cover_img" :src="icons.designer_cover_img" alt />
        </div>
        <div class="name">设计师</div>
      </div>
      <div class="exhibition" @click="enterStudyTourDetailPage">
        <div class="pics">
          <img v-show="icons.study_tour_cover_img" :src="icons.study_tour_cover_img" alt />
        </div>
        <div class="name">国际展览</div>
      </div>
    </div>
    <!-- 分割线 -->
    <van-divider />
    <!-- 最新精选 -->
    <div class="latest_picks">
      <div class="header flex flex-between flex-middle">
        <div class="title">最新精选</div>
        <div class="more" @click="$router.push('/all_latest_picks_list')">更多</div>
      </div>
      <!-- 精选列表 -->
      <div class="featured_list">
        <SubjectList :list="latestPicksList" @enterDetailPage="enterPicksDetailPage"></SubjectList>
      </div>
    </div>
    <!-- 分割线 -->
    <van-divider />
    <!-- 设计风格 -->
    <div class="topics">
      <div class="header flex flex-between flex-middle">
        <div class="title">设计风格</div>
        <div class="more" @click="$router.push('/all_quality_topics_list?from=recommend')">更多</div>
      </div>
      <div class="topics_list">
        <div class="horizontal_list">
          <!-- 水平滚动图片组 -->
          <div ref="photoGroup" class="photo_group flex flex-middle flex-center" style="transition: left 1.5s;">
            <div
              v-for="(item, index) in preferredTopicsListTopThree"
              :key="index"
              ref="photoItem"
              class="photo_item"
              @click="enterTopicsDetailPage(item)"
            >
              <div class="coverImg">
                <Cover :src="item.topic.coverImgUrl" :alt="item.topic.name" height="60%"></Cover>
              </div>
              <div class="cover flex flex-center flex-middle">
                <p>{{ item.topic.name }}</p>
                <div v-if="item.topic.viewableMember === 'vip1'" class="vip">
                  <img src="@/assets/images/icon/VIP.png" :alt="'VIP' + index" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 优选列表 -->
      <div class="preferred_list">
        <SubjectList :list="preferredTopicsListRestPart" @enterDetailPage="enterTopicsListDetailPage"></SubjectList>
      </div>
    </div>
    <!-- 分割线 -->
    <van-divider />
    <!-- 案例 -->
    <div class="case">
      <div class="header flex flex-between flex-middle">
        <div class="title">专案</div>
      </div>
      <!-- 封面图 -->
      <div
        v-if="JSON.stringify(worksCaseListTopOne) !== '{}'"
        class="coverImg"
        @click="
          enterWorksCaseDetailPage(
            worksCaseListTopOne.viewableMember,
            worksCaseListTopOne.id,
            worksCaseListTopOne.contentSourceId,
            worksCaseListTopOne.contentSourceBrandId
          )
        "
      >
        <Cover :src="worksCaseListTopOne.coverImgUrl" :alt="worksCaseListTopOne.title" height="60%"></Cover>
        <div v-if="worksCaseListTopOne.viewableMember === 'vip1'" class="vip">
          <img src="@/assets/images/icon/VIP.png" alt="VIP" />
        </div>
        <div class="caption">
          <p class=" line2">{{ worksCaseListTopOne.title }}</p>
        </div>
      </div>

      <!-- 案例列表 -->
      <div class="case_list">
        <SubjectList :list="worksCaseListRestPart" @enterDetailPage="enterCasesDetailPage"></SubjectList>
      </div>
      <!-- 更多按钮 -->
      <van-button v-if="worksCasePageInfo.totalCount !== 0" class="more" block @click="$router.push('/all_works_case')"
        >更&nbsp;多</van-button
      >
    </div>
    <div class="copyright">
      <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备20047268号</a>
    </div>
  </div>
</template>

<script>
import Cover from "@/components/Cover.vue";
import SubjectList from "@/components/SubjectList.vue";
import {
  ResultCodeEnum,
  latestPicksApi,
  preferredTopicsApi,
  worksCaseApi,
  carouselApi,
  configureApi,
} from "@/api/index.js";
import filterApi from "@/util/filter";
import { Dialog } from "vant";
export default {
  components: {
    Cover,
    SubjectList,
  },
  data() {
    return {
      // 轮播列表
      carouselList: [],
      // 精选
      latestPicksList: [],
      // 设计风格
      preferredTopicsList: [],
      // 作品案例
      worksCaseList: [],
      worksCasePageInfo: {},
      icons: {
        import_furniture_cover_img: "", // 进口家具icon
        international_trend_cover_img: "", // 国际趋势icon
        designer_cover_img: "", // 设计师icon
        study_tour_cover_img: "", // 国际展览icon
      },
    };
  },
  computed: {
    preferredTopicsListTopThree() {
      return this.preferredTopicsList.slice(0, 3);
    },
    preferredTopicsListRestPart() {
      return this.preferredTopicsList.slice(3);
    },
    worksCaseListTopOne() {
      return this.worksCaseList.slice(0, 1)[0] || {};
    },
    worksCaseListRestPart() {
      return this.worksCaseList.slice(1);
    },
  },
  mounted() {
    this.getCarouselList();
    this.getLatestPicksList();
    this.getPreferredTopicsList();
    this.getWorksCaseList();
    Object.keys(this.icons).forEach((key) => {
      this.getIconCoverImg(key);
    });
  },
  methods: {
    // 获取icons的图片，通过配置项接口获取
    async getIconCoverImg(code) {
      const res = await configureApi.getByCode({
        code,
      });
      if (res.code === ResultCodeEnum.success.code) {
        this.icons[code] = res.data.value;
        console.log(code, this.icons[code]);
      }
    },
    openVIP(item) {
      // 判断是否绑定了号码;
      if (!(this.$store.state.loginUser.loginPhone && this.$store.state.loginUser.loginPhone !== "")) {
        Dialog.confirm({
          message: "注册会员，获取更多进口品牌信息",
          confirmButtonText: "前往注册 >",
        })
          .then(() => {
            this.$router.push("/bind_phone");
            this.$uweb.trackEvent("注册会员，获取更多进口品牌信息", "前往注册", "首页推荐");
          })
          .catch(() => {
            this.$uweb.trackEvent("注册会员，获取更多进口品牌信息", "取消", "首页推荐");
          });
      } else {
        Dialog.close();
        if (
          item.viewableMember === "vip1" &&
          !this.$store.state.loginUser.isVip &&
          !(
            (this.$store.state.myAuthentication !== null &&
              this.$store.state.myAuthentication.status === "audit_pass" &&
              this.$store.state.myAuthentication.type !== "brand" &&
              this.$store.state.myAuthentication.contentSourceId === item.contentSourceId) ||
            (this.$store.state.myAuthentication !== null &&
              this.$store.state.myAuthentication.status === "audit_pass" &&
              this.$store.state.myAuthentication.type === "brand" &&
              this.$store.state.myAuthentication.authenticationDetail.brandId === item.contentSourceId)
          )
        ) {
          // 弹框 提示开通会员
          Dialog.alert({
            title: "仅VIP会员可看，邀请您加入VIP",
            message: `<div class="open_vip_label flex flex-center flex-middle" style="height: 2rem;border-radius: 0.2rem;background-color: rgb(245, 185, 105);margin-top:1rem;" ><p style="color: #81510a;">￥<span>${filterApi.centToYuan(
              this.$store.state.vipDetail.cost
            )}元/年</span>,开通VIP查看全部内容</p></div>`,
            allowHtml: true,
            closeOnClickOverlay: true,
            confirmButtonText: "取消",
          })
            .then(() => {
              this.$uweb.trackEvent("开通VIP查看全部内容", "取消", "首页推荐");
            })
            .catch(() => {});
          this.$nextTick(() => {
            document.querySelector(".open_vip_label").onclick = () => {
              this.$uweb.trackEvent("开通VIP查看全部内容", "支付", "首页推荐");
              this.$store.dispatch("vip/joinVip", { vipRankCode: "VIP1", remark: "" });
            };
          });
        } else {
          this.$router.push(item.target);
        }
      }
    },
    // 点击轮播图，进入详情页
    enterSwipeItemDetailPage(item) {
      let target = "";
      if (item.targetType === "url" && item.target !== null) {
        if (item.target.indexOf("?") > 0) {
          target = item.target + "&isShared=true";
        } else {
          target = item.target + "?isShared=true";
        }
        this.$router.push(target);
        // if (item.contentType === "vr") {
        //   this.$router.push(item.target);
        // } else {
        //   // 图文/视频/案例/品牌
        //   // 判断范围是否是普通用户还是注册用户
        //   if (item.viewableMember === "member") {
        //     this.$router.push(item.target);
        //   } else {
        //     this.openVIP(item);
        //   }
        // }
      }
    },
    // 最新精选列表
    async getLatestPicksList() {
      const res = await latestPicksApi.latestPicks({ pageNum: 0, pageSize: 3 });
      if (res.code === ResultCodeEnum.success.code) {
        this.latestPicksList = res.data.rows;
      }
    },
    // 设计风格列表
    async getPreferredTopicsList() {
      const res = await preferredTopicsApi.preferredTopics({ pageNum: 0, pageSize: 5 });
      if (res.code === ResultCodeEnum.success.code) {
        this.preferredTopicsList = res.data.rows;
      }
    },
    // 作品案例列表
    async getWorksCaseList() {
      const res = await worksCaseApi.worksCase({ pageNum: 0, pageSize: 3 });
      if (res.code === ResultCodeEnum.success.code) {
        this.worksCaseList.push(...res.data.rows);
        this.worksCasePageInfo = res.data.pageInfo;
      }
    },
    // 获取轮播图
    async getCarouselList() {
      const res = await carouselApi.carousel({
        belongPage: "home",
      });
      if (res.code === ResultCodeEnum.success.code) {
        this.carouselList = res.data;
      }
    },
    // 点击【进口品牌】，进入【进口品牌】页面
    enterImportedBrandPage() {
      this.$router.push("/book");
    },
    // 点击【国际趋势】，进入【国际趋势】页面
    enterInternationPage() {
      this.$router.push("/international_list");
    },
    // 点击【国际展览】，进入【国际展览】页面
    enterStudyTourDetailPage() {
      this.$router.push("/study_tour_list");
    },
    // 点击【设计师】，进入【设计师】页面
    enterDesignPage() {
      this.$router.push("/designer");
    },
    // 点击【设计风格】水平图片组的专题，进入专题详情页面
    enterTopicsDetailPage(item) {
      // 判断范围是否是普通用户还是注册用户
      if (item.topic.viewableMember === "member") {
        this.$router.push({
          path: "/quality_topics_detail",
          query: {
            id: item.id,
            from: "recommend",
          },
        });
      } else {
        // 判断是否绑定了号码;
        if (!(this.$store.state.loginUser.loginPhone && this.$store.state.loginUser.loginPhone !== "")) {
          Dialog.confirm({
            message: "注册会员，获取更多进口品牌信息",
            confirmButtonText: "前往注册 >",
          })
            .then(() => {
              this.$router.push("/bind_phone");
              this.$uweb.trackEvent("注册会员，获取更多进口品牌信息", "前往注册", "首页推荐");
            })
            .catch(() => {
              this.$uweb.trackEvent("注册会员，获取更多进口品牌信息", "取消", "首页推荐");
            });
        } else {
          Dialog.close();
          if (item.topic.viewableMember === "vip1" && !this.$store.state.loginUser.isVip) {
            // 弹框 提示开通会员
            Dialog.alert({
              title: "仅VIP会员可看，邀请您加入VIP",
              message: `<div class="open_vip_label flex flex-center flex-middle" style="height: 2rem;border-radius: 0.2rem;background-color: rgb(245, 185, 105);margin-top:1rem;" ><p style="color: #81510a;">￥<span>${filterApi.centToYuan(
                this.$store.state.vipDetail.cost
              )}元/年</span>,开通VIP查看全部内容</p></div>`,
              allowHtml: true,
              closeOnClickOverlay: true,
              confirmButtonText: "取消",
            })
              .then(() => {
                this.$uweb.trackEvent("开通VIP查看全部内容", "取消", "首页推荐");
              })
              .catch(() => {});
            this.$nextTick(() => {
              document.querySelector(".open_vip_label").onclick = () => {
                this.$uweb.trackEvent("开通VIP查看全部内容", "支付", "首页推荐");
                this.$store.dispatch("vip/joinVip", { vipRankCode: "VIP1", remark: "" });
              };
            });
          } else {
            this.$router.push({
              path: "/quality_topics_detail",
              query: {
                id: item.id,
                from: "recommend",
              },
            });
          }
        }
      }
    },
    // 点击【设计风格】列表，进入专题详情页面
    enterTopicsListDetailPage(index) {
      const currentItem = this.preferredTopicsListRestPart.find((item, i) => index === i);
      // 判断范围是否是普通用户还是注册用户
      if (currentItem.topic.viewableMember === "member") {
        this.$router.push({
          path: "/quality_topics_detail",
          query: {
            id: currentItem.id,
            from: "recommend",
          },
        });
      } else {
        // 判断是否绑定了号码;
        if (!(this.$store.state.loginUser.loginPhone && this.$store.state.loginUser.loginPhone !== "")) {
          Dialog.confirm({
            message: "注册会员，获取更多进口品牌信息",
            confirmButtonText: "前往注册 >",
          })
            .then(() => {
              this.$router.push("/bind_phone");
              this.$uweb.trackEvent("注册会员，获取更多进口品牌信息", "前往注册", "首页推荐");
            })
            .catch(() => {
              this.$uweb.trackEvent("注册会员，获取更多进口品牌信息", "取消", "首页推荐");
            });
        } else {
          Dialog.close();
          if (currentItem.viewableMember === "vip1" && !this.$store.state.loginUser.isVip) {
            // 弹框 提示开通会员
            Dialog.alert({
              title: "仅VIP会员可看，邀请您加入VIP",
              message: `<div class="open_vip_label flex flex-center flex-middle" style="height: 2rem;border-radius: 0.2rem;background-color: rgb(245, 185, 105);margin-top:1rem;" ><p style="color: #81510a;">￥<span>${filterApi.centToYuan(
                this.$store.state.vipDetail.cost
              )}元/年</span>,开通VIP查看全部内容</p></div>`,
              allowHtml: true,
              closeOnClickOverlay: true,
              confirmButtonText: "取消",
            })
              .then(() => {
                this.$uweb.trackEvent("开通VIP查看全部内容", "取消", "首页推荐");
              })
              .catch(() => {});
            this.$nextTick(() => {
              document.querySelector(".open_vip_label").onclick = () => {
                this.$uweb.trackEvent("开通VIP查看全部内容", "支付", "首页推荐");
                this.$store.dispatch("vip/joinVip", { vipRankCode: "VIP1", remark: "" });
              };
            });
          } else {
            this.$router.push({
              path: "/quality_topics_detail",
              query: {
                id: currentItem.id,
                from: "recommend",
              },
            });
          }
        }
      }
    },
    // 点击【最新精选】，进入详情
    enterPicksDetailPage(index) {
      const currentItem = this.latestPicksList.find((item, i) => index === i);
      // 判断类型是图文/视频/案例
      switch (currentItem.contentType) {
        case "image_text":
          // 判断范围是否是普通用户还是注册用户
          if (currentItem.content.viewableMember === "member") {
            this.$router.push({
              path: "/image_text_detail",
              query: {
                id: currentItem.contentId,
              },
            });
          } else {
            this.open(currentItem);
          }
          break;
        case "video":
          // 判断范围是否是普通用户还是注册用户
          if (currentItem.content.viewableMember === "member") {
            this.$router.push({
              path: "/video_detail",
              query: {
                id: currentItem.contentId,
              },
            });
          } else {
            this.open(currentItem);
          }
          break;
        case "works_case":
          // 判断范围是否是普通用户还是注册用户
          if (currentItem.content.viewableMember === "member") {
            this.$router.push({
              path: "/works_case_detail",
              query: {
                id: currentItem.contentId,
              },
            });
          } else {
            this.open(currentItem);
          }
          break;
      }
    },
    open(currentItem) {
      // 判断是否绑定了号码;
      if (!(this.$store.state.loginUser.loginPhone && this.$store.state.loginUser.loginPhone !== "")) {
        Dialog.confirm({
          message: "注册会员，获取更多进口品牌信息",
          confirmButtonText: "前往注册 >",
        })
          .then(() => {
            this.$router.push("/bind_phone");
            this.$uweb.trackEvent("注册会员，获取更多进口品牌信息", "前往注册", "首页推荐");
          })
          .catch(() => {
            this.$uweb.trackEvent("注册会员，获取更多进口品牌信息", "取消", "首页推荐");
          });
      } else {
        Dialog.close();
        if (
          currentItem.content.viewableMember === "vip1" &&
          !this.$store.state.loginUser.isVip &&
          !(
            (this.$store.state.myAuthentication !== null &&
              this.$store.state.myAuthentication.status === "audit_pass" &&
              this.$store.state.myAuthentication.type !== "brand" &&
              this.$store.state.myAuthentication.contentSourceId === currentItem.content.contentSourceId) ||
            (this.$store.state.myAuthentication !== null &&
              this.$store.state.myAuthentication.status === "audit_pass" &&
              this.$store.state.myAuthentication.type === "brand" &&
              this.$store.state.myAuthentication.authenticationDetail.brandId ===
                currentItem.content.contentSourceBrandId)
          )
        ) {
          // 弹框 提示开通会员
          Dialog.alert({
            title: "仅VIP会员可看，邀请您加入VIP",
            message: `<div class="open_vip_label flex flex-center flex-middle" style="height: 2rem;border-radius: 0.2rem;background-color: rgb(245, 185, 105);margin-top:1rem;" ><p style="color: #81510a;">￥<span>${filterApi.centToYuan(
              this.$store.state.vipDetail.cost
            )}元/年</span>,开通VIP查看全部内容</p></div>`,
            allowHtml: true,
            closeOnClickOverlay: true,
            confirmButtonText: "取消",
          })
            .then(() => {
              this.$uweb.trackEvent("开通VIP查看全部内容", "取消", "首页推荐");
            })
            .catch(() => {});
          this.$nextTick(() => {
            document.querySelector(".open_vip_label").onclick = () => {
              this.$uweb.trackEvent("开通VIP查看全部内容", "支付", "首页推荐");
              this.$store.dispatch("vip/joinVip", { vipRankCode: "VIP1", remark: "" });
            };
          });
        } else {
          switch (currentItem.contentType) {
            case "image_text":
              this.$router.push({
                path: "/image_text_detail",
                query: {
                  id: currentItem.contentId,
                },
              });
              break;
            case "video":
              this.$router.push({
                path: "/video_detail",
                query: {
                  id: currentItem.contentId,
                },
              });
              break;
            case "works_case":
              this.$router.push({
                path: "/works_case_detail",
                query: {
                  id: currentItem.contentId,
                },
              });
              break;
          }
        }
      }
    },
    // 点击案例，进入案例详情页面
    enterWorksCaseDetailPage(viewableMember, id, contentSourceId, contentSourceBrandId) {
      // 判断范围是否是普通用户还是注册用户
      if (viewableMember === "member") {
        this.$router.push({
          path: "/works_case_detail",
          query: {
            id: id,
          },
        });
      } else {
        // 判断是否绑定了号码;
        if (!(this.$store.state.loginUser.loginPhone && this.$store.state.loginUser.loginPhone !== "")) {
          Dialog.confirm({
            message: "注册会员，获取更多进口品牌信息",
            confirmButtonText: "前往注册 >",
          })
            .then(() => {
              this.$router.push("/bind_phone");
              this.$uweb.trackEvent("注册会员，获取更多进口品牌信息", "前往注册", "首页推荐");
            })
            .catch(() => {
              this.$uweb.trackEvent("注册会员，获取更多进口品牌信息", "取消", "首页推荐");
            });
        } else {
          Dialog.close();
          if (
            viewableMember === "vip1" &&
            !this.$store.state.loginUser.isVip &&
            !(
              (this.$store.state.myAuthentication !== null &&
                this.$store.state.myAuthentication.status === "audit_pass" &&
                this.$store.state.myAuthentication.type !== "brand" &&
                this.$store.state.myAuthentication.contentSourceId === contentSourceId) ||
              (this.$store.state.myAuthentication !== null &&
                this.$store.state.myAuthentication.status === "audit_pass" &&
                this.$store.state.myAuthentication.type === "brand" &&
                this.$store.state.myAuthentication.authenticationDetail.brandId === contentSourceBrandId)
            )
          ) {
            // 弹框 提示开通会员
            Dialog.alert({
              title: "仅VIP会员可看，邀请您加入VIP",
              message: `<div class="open_vip_label flex flex-center flex-middle" style="height: 2rem;border-radius: 0.2rem;background-color: rgb(245, 185, 105);margin-top:1rem;" ><p style="color: #81510a;">￥<span>${filterApi.centToYuan(
                this.$store.state.vipDetail.cost
              )}元/年</span>,开通VIP查看全部内容</p></div>`,
              allowHtml: true,
              closeOnClickOverlay: true,
              confirmButtonText: "取消",
            })
              .then(() => {
                this.$uweb.trackEvent("开通VIP查看全部内容", "取消", "首页推荐");
              })
              .catch(() => {});
            this.$nextTick(() => {
              document.querySelector(".open_vip_label").onclick = () => {
                this.$uweb.trackEvent("开通VIP查看全部内容", "支付", "首页推荐");
                this.$store.dispatch("vip/joinVip", { vipRankCode: "VIP1", remark: "" });
              };
            });
          } else {
            this.$router.push({
              path: "/works_case_detail",
              query: {
                id: id,
              },
            });
          }
        }
      }
    },
    enterCasesDetailPage(index) {
      const currentItem = this.worksCaseListRestPart.find((item, i) => index === i);
      // 判断范围是否是普通用户还是注册用户
      if (currentItem.viewableMember === "member") {
        this.$router.push({
          path: "/works_case_detail",
          query: {
            id: currentItem.id,
          },
        });
      } else {
        // 判断是否绑定了号码;
        if (!(this.$store.state.loginUser.loginPhone && this.$store.state.loginUser.loginPhone !== "")) {
          Dialog.confirm({
            message: "注册会员，获取更多进口品牌信息",
            confirmButtonText: "前往注册 >",
          })
            .then(() => {
              this.$router.push("/bind_phone");
              this.$uweb.trackEvent("注册会员，获取更多进口品牌信息", "前往注册", "首页推荐");
            })
            .catch(() => {
              this.$uweb.trackEvent("注册会员，获取更多进口品牌信息", "取消", "首页推荐");
            });
        } else {
          Dialog.close();
          if (
            currentItem.viewableMember === "vip1" &&
            !this.$store.state.loginUser.isVip &&
            !(
              (this.$store.state.myAuthentication !== null &&
                this.$store.state.myAuthentication.status === "audit_pass" &&
                this.$store.state.myAuthentication.type !== "brand" &&
                this.$store.state.myAuthentication.contentSourceId === currentItem.contentSourceId) ||
              (this.$store.state.myAuthentication !== null &&
                this.$store.state.myAuthentication.status === "audit_pass" &&
                this.$store.state.myAuthentication.type === "brand" &&
                this.$store.state.myAuthentication.authenticationDetail.brandId === currentItem.contentSourceBrandId)
            )
          ) {
            // 弹框 提示开通会员
            Dialog.alert({
              title: "仅VIP会员可看，邀请您加入VIP",
              message: `<div class="open_vip_label flex flex-center flex-middle" style="height: 2rem;border-radius: 0.2rem;background-color: rgb(245, 185, 105);margin-top:1rem;" ><p style="color: #81510a;">￥<span>${filterApi.centToYuan(
                this.$store.state.vipDetail.cost
              )}元/年</span>,开通VIP查看全部内容</p></div>`,
              allowHtml: true,
              closeOnClickOverlay: true,
              confirmButtonText: "取消",
            })
              .then(() => {
                this.$uweb.trackEvent("开通VIP查看全部内容", "取消", "首页推荐");
              })
              .catch(() => {});
            this.$nextTick(() => {
              document.querySelector(".open_vip_label").onclick = () => {
                this.$uweb.trackEvent("开通VIP查看全部内容", "支付", "首页推荐");
                this.$store.dispatch("vip/joinVip", { vipRankCode: "VIP1", remark: "" });
              };
            });
          } else {
            this.$router.push({
              path: "/works_case_detail",
              query: {
                id: currentItem.id,
              },
            });
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.recommend_container .carousel {
  position: relative;
}
.recommend_container .carousel .coverImg {
  width: 100%;
  height: 10.5rem;
}
.recommend_container .carousel .title {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0.5rem 0.4rem 0.3rem 1rem;
  z-index: 10;
  color: #fff;
  font-size: 0.8rem;
  background-color: rgba(0, 0, 0, 0.2);
}
.recommend_container .options {
  margin: 1rem 0;
}
.recommend_container .options .pics {
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: 0.5rem;
  border-radius: 50%;
  overflow: hidden;
}
.recommend_container .options .pics img {
  width: 100%;
  height: 100%;
}
.recommend_container .options > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.latest_picks,
.topics {
  padding: 1.5rem 0;
}
.latest_picks .header,
.topics .header,
.case .header {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.latest_picks .header .more,
.topics .header .more {
  font-size: 0.7rem;
  color: #bbb;
  font-weight: normal;
  cursor: pointer;
}
.topics .topics_list {
  width: 100%;
  margin-bottom: 1.5rem;
  overflow-x: scroll;
  overflow-y: hidden;
  // IOS滑动卡顿问题
  -webkit-overflow-scrolling: touch;
}
// 滚动样式
::-webkit-scrollbar {
  height: 0;
  background-color: #fff;
}
.topics .topics_list .coverImg {
  width: 100%;
  height: 5rem;
}
.topics .horizontal_list {
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.topics .photo_item {
  width: 8rem;
  margin-right: 0.4rem;
  position: relative;
  float: left;
}
.topics .photo_item:last-child {
  margin-right: 0;
}
.topics .photo_item .vip {
  position: absolute;
  left: 0;
  top: 0;
  width: 1.8rem;
  height: 0.9rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.topics .photo_item .cover {
  width: 100%;
  height: 100%;
  font-size: 0.8rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 0;
}
.topics .photo_item .cover p {
  width: 6rem;
  text-align: center;
}
.case {
  padding-top: 0.8rem;
  .header {
    margin-bottom: 0.8rem;
  }
}
.case .coverImg {
  margin-bottom: 1rem;
  height: 10.5rem;
}
.case .coverImg /deep/ .cover-img:before {
  content: "专案";
  position: absolute;
  left: -0.5rem;
  top: -1.25rem;
  width: 3.75rem;
  height: 2.75rem;
  line-height: 3.75rem;
  text-align: center;
  border-bottom-right-radius: 50%;
  color: #fff;
  background-color: #000;
  z-index: 1;
}
.case .vip {
  position: absolute;
  left: 0;
  top: 0;
  width: 2.4rem;
  height: 1.2rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.case .caption {
  width: 100%;
  font-size: 0.8rem;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.2);
}
.case .more {
  color: #fff;
  margin-bottom: 0.8rem;
  background-color: #333333;
}
.copyright {
  margin: 0.4rem 0;
  text-align: center;
  a {
    color: #7d7e80;
  }
}
@keyframes scrollX {
  0% {
    left: 0;
  }
  100% {
    left: -8rem;
  }
}
</style>
