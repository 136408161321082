import {
  ResultCodeEnum,
  videoApi,
  myCenterApi,
  imageTextApi,
  uploadFileApi,
  collectApi,
  dictionaryApi,
  districtApi,
  memberAuthenticationApi,
  vipApi,
} from "@/api/index.js";
import { Toast } from "vant";
import sdkApi from "@/util/wxShare.js";
export default {
  // 文件上传
  async uploadFile({ rootState }, file) {
    const fileData = new FormData();
    fileData.append("file", file);
    const res = await uploadFileApi.uploadFile(fileData);
    if (res.code === ResultCodeEnum.success.code) {
      rootState.fileUrl = res.data;
    }
  },
  // 当前登录用户信息
  async getLoginUserInfo({ rootState }) {
    const res = await myCenterApi.getLoginUser();
    if (res.code === ResultCodeEnum.success.code) {
      rootState.loginUser = res.data;
      if (/^\/bind_phone/.test(rootState.route.path) && rootState.bindSuccessFlag) {
        window.history.go(-1);
      }
    }
  },
  // 图文列表
  // async getImageTextList({ rootState }) {
  //   const res = await imageTextApi.imageText({
  //     pageNum: 0,
  //     pageSize: 5,
  //   });
  //   if (res.code === ResultCodeEnum.success.code) {
  //     rootState.imageTextList = res.data.rows;
  //   }
  // },
  // 根据id获取图文详情
  async getImageTextById({ rootState }, id) {
    const res = await imageTextApi.imageTextById(id);
    if (res.code === ResultCodeEnum.success.code) {
      rootState.imageTextDetail = res.data;
      if (rootState.route.query.fromShare) {
        document.title = res.data.title;
        rootState.fullPath = rootState.route.fullPath;
      } else {
        if (rootState.route.fullPath.indexOf("?") > 0) {
          rootState.fullPath = rootState.route.fullPath + "&fromShare=true";
        } else {
          rootState.fullPath = rootState.route.fullPath + "?fromShare=true";
        }
      }
      // 调用微信分享接口
      sdkApi.getJSSDK({
        title: "意米家 Foresee Find",
        desc: rootState.imageTextDetail.title,
        link: window.location.href.split("#")[0] + `?ts=${Date.now()}#` + rootState.fullPath,
        imgUrl: "http://oss-benma-test.benma-code.com/vl/img/xHfM1mSlOmtFCqEsJMuwMMIxtW68odgH.png",
      });
    }
  },
  // 视频列表
  // async getVideoList({ rootState }) {
  //   const res = await videoApi.video(rootState.videoQuery);
  //   if (res.code === ResultCodeEnum.success.code) {
  //     rootState.videoList = res.data.rows;
  //   }
  // },
  // 根据id获取视频详情
  async getVideoById({ rootState }, id) {
    const res = await videoApi.videoById(id);
    if (res.code === ResultCodeEnum.success.code) {
      rootState.videoDetail = res.data;
      if (rootState.route.query.fromShare) {
        document.title = res.data.title;
        rootState.fullPath = rootState.route.fullPath;
      } else {
        if (rootState.route.fullPath.indexOf("?") > 0) {
          rootState.fullPath = rootState.route.fullPath + "&fromShare=true";
        } else {
          rootState.fullPath = rootState.route.fullPath + "?fromShare=true";
        }
      }
      // 调用微信分享接口
      sdkApi.getJSSDK({
        title: "意米家 Foresee Find",
        desc: rootState.videoDetail.title,
        link: window.location.href.split("#")[0] + `?ts=${Date.now()}#` + rootState.fullPath,
        imgUrl: "http://oss-benma-test.benma-code.com/vl/img/xHfM1mSlOmtFCqEsJMuwMMIxtW68odgH.png",
      });
    }
  },
  // 判断是否收藏
  async isCollect({ rootState }, payload) {
    const res = await collectApi.isCollected({ collectType: payload.type, collectId: payload.id });
    if (res.code === ResultCodeEnum.success.code) {
      rootState.collectOrNot = res.data;
    }
  },
  // 根据id收藏
  async getCollectById({ rootState }, payload) {
    const res = await collectApi.collectById(payload.id, { collectType: payload.type });
    if (res.code === ResultCodeEnum.success.code) {
      rootState.collectOrNot = true;
      Toast("收藏了");
    }
  },
  // 根据id取消收藏
  async cancelCollectById({ rootState }, payload) {
    const res = await collectApi.deleteCollectById(payload.id, { collectType: payload.type });
    if (res.code === ResultCodeEnum.success.code) {
      rootState.collectOrNot = false;
      Toast("取消收藏");
    }
  },

  // 判断是否提交（设计师/设计机构）的认证申请
  async getMyAuthentication({ rootState }) {
    const res = await memberAuthenticationApi.myAuthentication();
    if (res.code === ResultCodeEnum.success.code) {
      // 已提交申请
      rootState.myAuthentication = res.data;
    }
  },
  // 获取字典值
  async getDictValueList({ rootState }, params) {
    const res = await dictionaryApi.dictValueList(params);
    if (res.code === ResultCodeEnum.success.code) {
      if (params.code === "countries_and_regions") {
        rootState.dictionaryListAboutCountry = res.data;
      } else if (params.code === "advantage_field") {
        rootState.dictionaryListAboutField = res.data;
      } else {
        rootState.dictionaryList = res.data;
      }
    }
  },
  // 获取所有的省份+城市
  async getProvincesAndCitiesList({ rootState }) {
    const res = await districtApi.listProvincesAndCities();
    if (res.code === ResultCodeEnum.success.code) {
      rootState.provincesAndCitiesList = res.data;
    }
  },
  // 获取会员详情
  async getVip({ rootState }) {
    const res = await vipApi.getVip();
    if (res.code === ResultCodeEnum.success.code) {
      rootState.vipDetail = res.data;
    }
  },
};
