import request from "@/util/request";

const importFurniturePrefix = "/v1/member/importFurniture";

export default {
  getTopicById(id) {
    return request.get(`${importFurniturePrefix}/getTopic/${id}`);
  },
  productionClassifyList(params) {
    return request.get(`${importFurniturePrefix}/productionClassifyList`, params);
  },
  topicList(params) {
    return request.get(`${importFurniturePrefix}/topicList`, params);
  },
};
