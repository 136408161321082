import request from "@/util/request";
const myCenterPrefix = "/v1/member/myCenter";

export default {
  getLoginUser: () => {
    return request.get(`${myCenterPrefix}/getLoginUser`);
  },
  myCollection: (params) => {
    return request.get(`${myCenterPrefix}/myCollection`, params);
  },
  counts: () => {
    return request.get(`${myCenterPrefix}/counts`);
  },
  myFollowerList(params) {
    return request.get(`${myCenterPrefix}/myFollowerList`, params);
  },
  myContentList(params) {
    return request.get(`${myCenterPrefix}/myContentList`, params);
  },
  hotContentList(params) {
    return request.get(`${myCenterPrefix}/hotContentList`, params);
  },
  myFansList(params) {
    return request.get(`${myCenterPrefix}/myFansList`, params);
  },
  cancelFollow(id) {
    return request.delete(`${myCenterPrefix}/cancelFollow/${id}`);
  },
  follow(id) {
    return request.post(`${myCenterPrefix}/follow/${id}`);
  },
  bindLoginPhone: (params) => {
    return request.put(`${myCenterPrefix}/bindLoginPhone`, params);
  },
  updatePersonalInfo: (params) => {
    return request.put(`${myCenterPrefix}/updatePersonalInfo`, params);
  },
  updatePwd: (params) => {
    return request.put(`${myCenterPrefix}/updatePwd`, params);
  },
  contentList: (params) => {
    return request.get(`${myCenterPrefix}/contentList`, params);
  },
};
