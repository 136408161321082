import Area from "@/assets/area";
// 过滤年月日时分秒
const filterTimeStamp = (timeStamp) => {
  if (!timeStamp) {
    return;
  }
  const date = new Date(timeStamp);
  const dateFormat = (date) => (date < 10 ? `0${date}` : date);
  const [Y, M, D, h, m, s] = [
    date.getFullYear(),
    dateFormat(date.getMonth() + 1),
    dateFormat(date.getDate()),
    dateFormat(date.getHours()),
    dateFormat(date.getMinutes()),
    dateFormat(date.getSeconds()),
  ];
  return `${Y}-${M}-${D} ${h}:${m}:${s}`;
};
// 过滤年月日
const filterTimeStampToYMD = (timeStamp) => {
  if (!timeStamp) {
    return;
  }
  const date = new Date(timeStamp);
  const dateFormat = (date) => (date < 10 ? `0${date}` : date);
  const [Y, M, D] = [date.getFullYear(), dateFormat(date.getMonth() + 1), dateFormat(date.getDate())];
  return `${Y}-${M}-${D}`;
};
// 过滤年月日
const filterTimeStampToYMD2 = (timeStamp) => {
  if (!timeStamp) {
    return;
  }
  const date = new Date(timeStamp);
  const dateFormat = (date) => (date < 10 ? `0${date}` : date);
  const [Y, M, D] = [date.getFullYear(), dateFormat(date.getMonth() + 1), dateFormat(date.getDate())];
  return `${Y}/${M}/${D}`;
};
// 过滤年月
const filterTimeStampToYM = (timeStamp) => {
  if (!timeStamp) {
    return;
  }
  const date = new Date(timeStamp);
  const dateFormat = (date) => (date < 10 ? `0${date}` : date);
  const [Y, M] = [date.getFullYear(), dateFormat(date.getMonth() + 1)];
  return `${Y}-${M}`;
};
// 过滤年
const filterTimeStampToY = (timeStamp) => {
  if (!timeStamp) {
    return;
  }
  const date = new Date(timeStamp);
  const Y = date.getFullYear();
  return `${Y}`;
};
// 将秒数过滤成分秒
const filterSeconds = (seconds) => {
  if (!seconds) {
    return;
  }
  const timeFunc = (time) => (time < 10 ? `0${time}` : time); // 小于10前面补全0
  const h = timeFunc(Math.floor(seconds > 60 * 60 ? seconds / 3600 : 0));
  const m = timeFunc(Math.floor(seconds > 60 ? seconds / 60 : 0));
  const s = timeFunc(Math.floor(seconds % 60));
  if (h > 0) {
    return `${h}:${m}:${s}`;
  }
  return `${m}:${s}`;
};
const formatTimeStamp = (timeStamp) => {
  if (!timeStamp) {
    return;
  }
  const dateFormat = (date) => (date < 10 ? `0${date}` : date);
  const date = new Date(timeStamp);
  const interval = Math.abs(new Date().getTime() - timeStamp);
  if (interval > 7 * 24 * 60 * 60 * 1000) {
    const [Y, M, D] = [date.getFullYear(), dateFormat(date.getMonth() + 1), dateFormat(date.getDate())];
    // 如 2020/08/03 09：30
    return Y + "/" + M + "/" + D;
  } else if (interval > 24 * 60 * 60 * 1000) {
    // 一周之内
    const day = Math.floor(interval / (24 * 60 * 60 * 1000));
    return day + "天前";
  } else if (interval > 60 * 60 * 1000) {
    // 一天之内
    const hour = Math.floor(interval / (60 * 60 * 1000));
    return hour + "小时前";
  } else if (interval > 60 * 1000) {
    // 一天之内
    const minute = Math.floor(interval / (60 * 1000));
    return minute + "分钟前";
  }
};
// 地区转为地区代码
function changeAddressToCode(address) {
  if (address[address.length - 1]) {
    return address[address.length - 1].code + "000000";
  } else {
    let i = address.length - 2;
    while (i >= 0) {
      if (address[i]) {
        return address[i].code + "000000";
      } else {
        i--;
      }
    }
  }
}

// 地区代码转为地区
function changeCodeToAddress(code) {
  code = code + "";
  for (const key in Area["county_list"]) {
    if (key === code.slice(0, 6)) {
      const address = Area["county_list"][key];
      return address;
    }
  }
}
// 过滤数字
export function filterNumber(num) {
  // 大于十亿
  if (num >= 10 * 10000 * 10000) {
    return Math.floor((num / (10 * 10000 * 10000)) * 10) / 10 + "b";
  }
  // 大于百万
  if (num >= 100 * 10000) {
    return Math.floor((num / (100 * 10000)) * 10) / 10 + "m";
  }
  // 大于一千
  // if (num >= 1000) {
  //   return Math.floor((num / 1000) * 10) / 10 + "k";
  // }
  return num;
}

// 分转化为元
function centToYuan(cost) {
  return cost / 100;
}

export default {
  filterTimeStamp,
  filterTimeStampToYMD,
  filterTimeStampToYMD2,
  filterTimeStampToYM,
  filterTimeStampToY,
  formatTimeStamp,
  filterSeconds,
  changeAddressToCode,
  changeCodeToAddress,
  filterNumber,
  centToYuan,
};
