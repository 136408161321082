<template>
  <div
    :class="[
      'inspiration_container',
      'flex',
      'flex-column',
      caseList.length !== 0 ? 'max_content_height' : 'percent_height',
    ]"
  >
    <van-divider />
    <!-- 可选选项区域 -->
    <div class="select">
      <MenuBar :options="options" @handleEvent="showPopup"></MenuBar>
      <!-- 【全部空间】 的弹出层 -->
      <van-popup
        :key="'space' + channelId"
        v-model="showSpace"
        position="bottom"
        class="space_popup"
        :style="{ height: '70%' }"
        @click-overlay="clickOverlaySpace"
      >
        <div class="area flex flex-column">
          <div class="content flex-item">
            <div class="title flex flex-between flex-middle">
              <span>选择{{ currentPopupType }}</span>
              <a href="javascript:;" @click="clearSpaceAllActiveTag">清除</a>
            </div>
            <div class="options_space flex flex-wrap flex-middle">
              <van-tag
                v-for="(item, index) in spaces"
                :key="index"
                :class="['option_item_space' + item.id, 'line1']"
                @click="(e) => changeSpaceTagState(e, item.id)"
                >{{ item.name }}</van-tag
              >
            </div>
          </div>
          <van-button
            :style="{ marginBottom: this.$store.state.userAgent === 'Android' ? '0' : '3rem' }"
            class="confirm"
            @click="hideSpacePopup"
            >确 定</van-button
          >
        </div>
      </van-popup>
      <!-- 【全部风格】 的弹出层 -->
      <van-popup
        :key="'style' + channelId"
        v-model="showStyle"
        position="bottom"
        class="style_popup"
        :style="{ height: '70%' }"
        @click-overlay="clickOverlayStyle"
      >
        <div class="area flex flex-column">
          <div class="content flex-item">
            <div class="title flex flex-between flex-middle">
              <span>选择{{ currentPopupType }}</span>
              <a href="javascript:;" @click="clearStyleAllActiveTag">清除</a>
            </div>
            <div class="options_style flex flex-wrap  flex-middle">
              <van-tag
                v-for="(item, index) in styles"
                :key="index"
                :class="['option_item_style' + item.id, 'line1']"
                @click="(e) => changeStyleTagState(e, item.id)"
                >{{ item.name }}</van-tag
              >
            </div>
          </div>
          <van-button
            :style="{ marginBottom: this.$store.state.userAgent === 'Android' ? '0' : '3rem' }"
            class="confirm"
            @click="hideStylePopup"
            >确 定</van-button
          >
        </div>
      </van-popup>
    </div>
    <Empty v-if="pageInfo.totalCount === 0"></Empty>
    <!-- 列表 -->
    <!-- <WaterFallFlow v-else :list="caseList" :pagenum="contentQuery.pageNum"></WaterFallFlow> -->
    <div v-else class="list flex flex-between flex-wrap">
      <div
        v-for="(item, index) in caseList"
        :key="index"
        class="list_item flex flex-column"
        @click="clickListItem(item)"
      >
        <div class="coverImg">
          <Cover :src="item.coverImgUrl" alt=""></Cover>
          <div v-if="item.viewableMember === 'vip1'" class="vip">
            <img src="@/assets/images/icon/VIP.png" :alt="'VIP' + index" />
          </div>
        </div>
        <div class="content flex flex-column flex-between flex-item">
          <div class="title line2">{{ item.contentTitle }}</div>
          <div class="info flex flex-between flex-middle">
            <div class="designer flex-item-2 flex flex-middle">
              <div class="avatar">
                <img :src="item.contentSourceCoverImgUrl" alt="头像" />
              </div>
              <span class="name">{{ item.contentSourceName }}</span>
            </div>
            <div class="watcher flex-item-1 flex flex-middle flex-right">
              <img src="@/assets/images/icon/list-views.png" alt="" />
              <span> {{ item.readNum | filterNumber }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-loading v-if="available === 0 && !pageInfo.isLast" size="24px" color="#1989fa">加载中...</van-loading>
  </div>
</template>

<script>
import Cover from "@/components/Cover";
// import WaterFallFlow from "@/components/WaterFallFlow.vue";
import MenuBar from "@/components/MenuBar.vue";
import Empty from "@/components/Empty.vue";
import { ResultCodeEnum, labelApi, channelApi } from "@/api/index";
import { Dialog } from "vant";
import filterApi from "@/util/filter";
export default {
  components: {
    // WaterFallFlow,
    MenuBar,
    Empty,
    Cover,
  },
  props: {
    channelId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      // 控制弹出层的显示与隐藏
      showStyle: false,
      showSpace: false,
      query: {
        pageNum: 0,
        pageSize: 100,
        labelType: "space",
      },
      options: ["全部空间", "全部风格"],
      currentPopupType: "",
      // 全部空间
      spaces: [],
      spaceLabelIds: [],
      tempSpaceLabelIds: [],
      // 全部风格
      styles: [],
      styleLabelIds: [],
      tempStyleLabelIds: [],
      caseList: [],
      pageInfo: {},
      contentQuery: {
        pageNum: 0,
        pageSize: 8,
        spaceLabelIds: "",
        styleLabelIds: "",
      },
      available: 0,
    };
  },
  watch: {
    channelId() {
      this.spaceLabelIds = [];
      this.styleLabelIds = [];
      this.contentQuery.pageNum = 0;
      this.contentQuery.spaceLabelIds = "";
      this.contentQuery.styleLabelIds = "";
      this.available = 0;
      this.caseList = [];
      this.pageInfo = {};
      this.getContentList();
    },
  },
  mounted() {
    this.getLabelsList();
    this.getContentList();
    document.querySelector(".router_view").addEventListener("scroll", this.scrollEvent);
  },
  methods: {
    openVIP(item) {
      Dialog.close();
      if (
        item.viewableMember === "vip1" &&
        !this.$store.state.loginUser.isVip &&
        !(
          (this.$store.state.myAuthentication !== null &&
            this.$store.state.myAuthentication.status === "audit_pass" &&
            this.$store.state.myAuthentication.type !== "brand" &&
            this.$store.state.myAuthentication.contentSourceId === item.contentSourceId) ||
          (this.$store.state.myAuthentication !== null &&
            this.$store.state.myAuthentication.status === "audit_pass" &&
            this.$store.state.myAuthentication.type === "brand" &&
            this.$store.state.myAuthentication.authenticationDetail.brandId === item.contentSourceBrandId)
        )
      ) {
        // 弹框 提示开通会员
        Dialog.alert({
          title: "仅VIP会员可看，邀请您加入VIP",
          message: `<div class="open_vip_label_fall flex flex-center flex-middle" style="height: 2rem;border-radius: 0.2rem;background-color: rgb(245, 185, 105);margin-top:1rem;" ><p style="color: #81510a;">￥<span>${filterApi.centToYuan(
            this.$store.state.vipDetail.cost
          )}元/年</span>,开通VIP查看全部内容</p></div>`,
          allowHtml: true,
          closeOnClickOverlay: true,
          confirmButtonText: "取消",
        })
          .then(() => {
            this.$uweb.trackEvent("开通VIP查看全部内容", "取消", "首页频道");
          })
          .catch(() => {});
        this.$nextTick(() => {
          document.querySelector(".open_vip_label_fall").onclick = () => {
            this.$uweb.trackEvent("开通VIP查看全部内容", "支付", "首页频道");
            this.$store.dispatch("vip/joinVip", { vipRankCode: "VIP1", remark: "" });
          };
        });
      } else {
        // 判断类型是图文/视频/案例
        switch (item.contentType) {
          case "image_text":
            this.$router.push({
              path: "/image_text_detail",
              query: {
                id: item.contentId,
              },
            });
            break;
          case "works_case":
            this.$router.push({
              path: "/works_case_detail",
              query: {
                id: item.contentId,
              },
            });
            break;
        }
      }
    },
    // 点击列表项，跳转详情页面
    clickListItem(item) {
      // 判断范围是否是普通用户还是注册用户
      if (item.viewableMember === "member") {
        // 判断类型是图文/视频/案例
        switch (item.contentType) {
          case "image_text":
            this.$router.push({
              path: "/image_text_detail",
              query: {
                id: item.contentId,
              },
            });
            break;
          case "works_case":
            this.$router.push({
              path: "/works_case_detail",
              query: {
                id: item.contentId,
              },
            });
            break;
        }
      } else {
        // 判断是否绑定了号码;
        if (!(this.$store.state.loginUser.loginPhone && this.$store.state.loginUser.loginPhone !== "")) {
          Dialog.confirm({
            message: "注册会员，获取更多进口品牌信息",
            confirmButtonText: "前往注册 >",
          })
            .then(() => {
              this.$router.push("/bind_phone");
              this.$uweb.trackEvent("注册会员，获取更多进口品牌信息", "前往注册", "首页频道");
            })
            .catch(() => {
              this.$uweb.trackEvent("注册会员，获取更多进口品牌信息", "取消", "首页频道");
            });
        } else {
          this.openVIP(item);
        }
      }
    },
    scrollEvent() {
      // 获取滚轮位置
      const height1 = document.querySelector(".router_view").scrollTop;
      // 文档高度
      const height2 = document.querySelector(".router_view").scrollHeight;
      // 可视区域
      const height3 = document.querySelector(".router_view").clientHeight;
      // 如果滚动到最低（这里设置离最底还有100距离才触发函数）
      // available条件是为了防止触底时一直不断地请求。因此，请求一次后available为0，直到滚动到离底部超过100距离（即数据加载完后）才设为1
      if (height3 + height1 >= height2 && this.available && !this.pageInfo.isLast) {
        if (this.contentQuery.pageNum < this.pageInfo.totalPage) {
          this.contentQuery.pageNum++;
          this.available = 0;
          this.getContentList();
        }
      } else if (height3 + height1 < height2 - 30) {
        this.available = 1;
      }
    },
    // 获取对应频道列表
    async getContentList() {
      const res = await channelApi.getChannelContentList(this.channelId, this.contentQuery);
      if (res.code === ResultCodeEnum.success.code) {
        this.caseList.push(...res.data.rows);
        this.pageInfo = res.data.pageInfo;
      }
    },
    // 获取标签列表
    async getLabelsList() {
      // 获取空间
      const res = await labelApi.label(this.query);
      if (res.code === ResultCodeEnum.success.code) {
        this.spaces = res.data.rows;
        // 获取风格
        this.query.labelType = "style";
        const result = await labelApi.label(this.query);
        if (result.code === ResultCodeEnum.success.code) {
          this.styles = result.data.rows;
        }
      }
    },
    // 点击选项，显示弹出层
    showPopup(text) {
      this.currentPopupType = this.options.find((item) => item === text).slice(2);
      if (this.currentPopupType === "风格") {
        this.showStyle = true;
        this.showSpace = false;
      } else {
        this.showStyle = false;
        this.showSpace = true;
      }
    },
    // 【全部空间】 中的事件
    // 监听遮挡层点击事件
    changeSpaceTagState(e, id) {
      e.currentTarget.classList.toggle("active");
      // 保存当前被激活的标签
      if (e.currentTarget.classList.contains("active")) {
        this.tempSpaceLabelIds.push(id);
      } else {
        if (this.tempSpaceLabelIds.includes(id)) {
          const index = this.tempSpaceLabelIds.findIndex((item) => item === id);
          this.tempSpaceLabelIds.splice(index, 1);
        }
      }
    },
    // 点击确定
    hideSpacePopup() {
      // 删除重复的值
      this.tempSpaceLabelIds = [...new Set(this.tempSpaceLabelIds)];
      this.spaceLabelIds = JSON.parse(JSON.stringify(this.tempSpaceLabelIds));
      // 重新获取数据渲染
      this.contentQuery.spaceLabelIds = this.spaceLabelIds.join(",");
      // this.contentQuery.styleLabelIds = this.styleLabelIds.join(",");
      this.contentQuery.pageNum = 0; // 重新从第一页请求
      this.caseList = [];
      this.getContentList();
      this.showSpace = false;
    },
    // 点击遮挡层 --- 不保存这一次操作状态
    clickOverlaySpace() {
      // 找出两个数组相同的元素
      let arr = [];
      arr = this.tempSpaceLabelIds.filter((item) => this.spaceLabelIds.indexOf(item) !== -1);
      const labelList = this.tempSpaceLabelIds.concat(this.spaceLabelIds);
      // 筛选出发生变化的标签
      arr.forEach((item) => {
        while (labelList.indexOf(item) !== -1) {
          labelList.splice(labelList.findIndex((label) => label === item), 1);
        }
      });
      // 找到发生变化的标签对应的id
      labelList.forEach((item) => {
        document.querySelector(`.option_item_space${item}`).classList.toggle("active");
      });
      this.tempSpaceLabelIds = JSON.parse(JSON.stringify(this.spaceLabelIds));
    },
    // 点击清除按钮，删除所有被选中的选项标签的激活类
    clearSpaceAllActiveTag() {
      document.querySelector(".options_space").childNodes.forEach((item) => {
        item.classList.remove("active");
      });
      this.spaceLabelIds = [];
      this.tempSpaceLabelIds = [];
      this.contentQuery.spaceLabelIds = "";
      this.contentQuery.pageNum = 0; // 重新从第一页请求
      this.caseList = [];
      this.getContentList();
      this.showSpace = false;
      // });
    },

    // 【全部风格】
    // 监听遮挡层点击事件
    changeStyleTagState(e, id) {
      e.currentTarget.classList.toggle("active");
      // 保存当前被激活的标签
      if (e.currentTarget.classList.contains("active")) {
        this.tempStyleLabelIds.push(id);
      } else {
        if (this.tempStyleLabelIds.includes(id)) {
          const index = this.tempStyleLabelIds.findIndex((item) => item === id);
          this.tempStyleLabelIds.splice(index, 1);
        }
      }
    },
    // 点击确定
    hideStylePopup() {
      // 删除重复的值
      this.tempStyleLabelIds = [...new Set(this.tempStyleLabelIds)];
      this.styleLabelIds = JSON.parse(JSON.stringify(this.tempStyleLabelIds));
      // 重新获取数据渲染
      // this.contentQuery.spaceLabelIds = this.spaceLabelIds.join(",");
      this.contentQuery.styleLabelIds = this.styleLabelIds.join(",");
      this.contentQuery.pageNum = 0; // 重新从第一页请求
      this.caseList = [];
      this.getContentList();
      this.showStyle = false;
    },
    // 点击遮挡层 --- 不保存这一次操作状态
    clickOverlayStyle() {
      // 找出两个数组相同的元素
      let arr = [];
      arr = this.tempStyleLabelIds.filter((item) => this.styleLabelIds.indexOf(item) !== -1);
      const labelList = this.tempStyleLabelIds.concat(this.styleLabelIds);
      // 筛选出发生变化的标签
      arr.forEach((item) => {
        while (labelList.indexOf(item) !== -1) {
          labelList.splice(labelList.findIndex((label) => label === item), 1);
        }
      });
      // 找到发生变化的标签对应的id
      labelList.forEach((item) => {
        document.querySelector(`.option_item_style${item}`).classList.toggle("active");
      });
      this.tempStyleLabelIds = JSON.parse(JSON.stringify(this.styleLabelIds));
    },
    // 点击清除按钮，删除所有被选中的选项标签的激活类
    clearStyleAllActiveTag() {
      document.querySelector(".options_style").childNodes.forEach((item) => {
        item.classList.remove("active");
      });
      this.styleLabelIds = [];
      this.tempStyleLabelIds = [];
      this.contentQuery.styleLabelIds = "";
      this.contentQuery.pageNum = 0; // 重新从第一页请求
      this.caseList = [];
      this.getContentList();
      this.showStyle = false;
      // });
    },
  },
};
</script>

<style lang="less" scoped>
.inspiration_container {
  .select {
    margin-bottom: 0.6rem;
    .title {
      margin-bottom: 1rem;
      > span {
        font-size: 0.9rem;
        font-weight: 600;
      }
      > a {
        font-size: 0.7rem;
        color: #aaa;
      }
    }
    .space_popup,
    .style_popup {
      padding: 0;
      .area {
        width: 100%;
        height: 100%;
        position: relative;
        padding: 1.5rem 0;
        .content {
          position: relative;
          overflow-y: auto;
          padding: 0 1rem;
        }
        .confirm {
          margin: 0 1rem;
        }
      }
    }
    .options_space,
    .options_style {
      margin-bottom: 1rem;
      /deep/ .van-tag {
        text-align: center;
        display: block;
        font-size: 0.7rem;
        color: #000;
        line-height: 1.6rem;
        flex-basis: 28%;
        background-color: #f7f8fa;
        padding: 0.3rem 0.35rem;
      }
    }

    .options_space .active,
    .options_style .active,
    .confirm {
      color: #fff;
      background-color: rgb(51, 51, 51);
    }
  }
  .waterFall {
    position: relative;
  }
  .list {
    width: 100%;
    .list_item {
      flex-basis: 49%;
      margin-bottom: 1rem;
      .coverImg {
        width: 100%;
        height: 5rem;
      }
      box-shadow: 0px 0.2rem 0.1rem #f7f8fa;
      .vip {
        position: absolute;
        left: 0;
        top: 0;
        width: 1.8rem;
        height: 0.9rem;
        img {
          width: 100%;
          height: 100%;
          vertical-align: top;
        }
      }
      .content {
        width: 100%;
        padding: 0.4rem 0.3rem;
        .title {
          font-size: 0.8rem;
        }
        .info {
          margin: 0.4rem 0 0;
          font-size: 0.5rem;
          color: #bbb;
          .designer {
            .avatar {
              // border-radius: 2%;
              // overflow: hidden;
              width: 1.4rem;
              height: 1.4rem;
              // position: relative;
              // display: inline-block;
              margin-right: 0.2rem;
              img {
                // position: absolute;
                // left: 50%;
                // top: 50%;
                // transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                // max-height: 100%;
              }
            }
            .name {
              display: inline-block;
              width: 3rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .watcher {
            // text-align: right;
            img {
              width: 0.8rem;
              height: 0.8rem;
              margin-right: 2px;
            }
          }
        }
      }
    }
  }
  // vant组件
  /deep/ .van-loading {
    text-align: center;
  }
}
</style>
