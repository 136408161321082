import request from "@/util/request";
const worksCasePrefix = "/v1/member/worksCases";

export default {
  worksCase(params) {
    return request.get(`${worksCasePrefix}`, params);
  },

  worksCaseById(id, params) {
    return request.get(`${worksCasePrefix}/${id}`, params);
  },
};
