import request from "@/util/request";

const productPrefix = "/v1/member/productions";

export default {
  production(params) {
    return request.get(`${productPrefix}`, params);
  },
  productionClassifyList(params) {
    return request.get(`${productPrefix}/productionClassifyList`, params);
  },
  productionById(id) {
    return request.get(`${productPrefix}/${id}`);
  },
};
