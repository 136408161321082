<template>
  <div id="app" class="flex flex-column">
    <div class="router_view flex flex-column">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
      <router-link
        v-if="
          !(/^\/home/.test(currentPath) || /^\/imported_brand/.test(currentPath) || /^\/user/.test(currentPath)) &&
            $store.state.showHomeIcon
        "
        to="/home"
      >
        <div class="home_icon flex flex-center flex-middle">
          <img src="@/assets/images/icon/home_icon.png" alt="首页图标" />
        </div>
      </router-link>
    </div>
    <div
      :class="
        /^\/home/.test(currentPath) || /^\/imported_brand/.test(currentPath) || /^\/user/.test(currentPath)
          ? 'menuBar'
          : 'menuBar menuBar_hide'
      "
    >
      <div
        v-show="/^\/home/.test(currentPath) || /^\/imported_brand/.test(currentPath) || /^\/user/.test(currentPath)"
        class="menu flex"
      >
        <div class="menuItem flex flex-column flex-middle" @click="changePath('/home')">
          <img
            class="iconTabbar"
            :src="
              /^\/home/.test(currentPath)
                ? require('@/assets/images/icon/tab-index1.png')
                : require('@/assets/images/icon/tab-index2.png')
            "
            alt=""
          />
          <div class="menuText" :style="{ color: /^\/home/.test(currentPath) ? '#000' : '#999' }">YIMI</div>
        </div>
        <div class="menuItem flex flex-column flex-middle" @click="changePath('/imported_brand')">
          <img
            class="iconTabbar"
            :src="
              /^\/imported_brand/.test(currentPath)
                ? require('@/assets/images/icon/tab-furniture1.png')
                : require('@/assets/images/icon/tab-furniture2.png')
            "
            alt=""
          />
          <div class="menuText" :style="{ color: /^\/imported_brand/.test(currentPath) ? '#000' : '#999' }">
            进口品牌
          </div>
        </div>
        <div class="menuItem flex flex-column flex-middle" @click="showUserPage">
          <img
            class="iconTabbar"
            :src="
              /^\/user/.test(currentPath)
                ? require('@/assets/images/icon/tab-mine1.png')
                : require('@/assets/images/icon/tab-mine2.png')
            "
            alt=""
          />
          <div class="menuText" :style="{ color: /^\/user/.test(currentPath) ? '#000' : '#999' }">寒舍</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ResultCodeEnum, loginApi } from "@/api/index";
import { Toast } from "vant";
export default {
  data() {
    return {
      currentPath: "/home",
    };
  },
  watch: {
    $route(to, from) {
      Toast.clear();
      this.currentPath = to.path;
    },
  },
  created: function() {
    this.currentPath = this.$route.path;
  },

  mounted: function() {
    // 登录
    if (__ENV__ === "local") {
      // this.verifyWebLogin();
    } else {
      this.verifyLogin();
    }
    // 判断内核
    if (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("Adr") > -1) {
      this.$store.state.userAgent = "Android";
    } else if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      this.$store.state.userAgent = "IOS";
    }
  },
  // 方法
  methods: {
    // 判断是否登录
    // async verifyWebLogin() {
    //   const res = await loginApi.web.verifyLogin();
    //   if (res.code === ResultCodeEnum.unauthenticated.code) {
    //     // TODO：发布测试环境要注释掉
    //     this.onSimulationLogin();
    //   } else if (res.code === ResultCodeEnum.success.code) {
    //     this.$store.dispatch("getLoginUserInfo"); // 获取当前用户信息
    //     this.$store.dispatch("getMyAuthentication"); // 获取当前用户认证身份信息
    //     // 获取会员详情
    //     this.$store.dispatch("getVip");
    //   }
    // },
    // // 模拟登录
    // async onSimulationLogin() {
    //   const res = await loginApi.web.login({
    //     loginName: "13609748850",
    //     password: "123456",
    //   });
    //   if (res.code === ResultCodeEnum.success.code) {
    //     // 请求成功后处理逻辑
    //     this.$store.dispatch("getLoginUserInfo"); // 获取当前用户信息
    //     this.$store.dispatch("getMyAuthentication"); // 获取当前用户认证身份信息
    //   }
    // },
    // 微信公众号登录
    async verifyLogin() {
      const res = await loginApi.wxmp.verifyLogin({
        refererUrl: location.href.split("#")[1],
      });
      if (res.code === ResultCodeEnum.success.code) {
        // 请求成功后处理逻辑
        this.$store.dispatch("getLoginUserInfo"); // 获取当前用户信息
        this.$store.dispatch("getMyAuthentication"); // 获取当前用户认证身份信息
        // 获取会员详情
        this.$store.dispatch("getVip");
      } else {
        // 请求失败 个人中心显示未登录页面
      }
    },

    changePath: function(path) {
      this.$router.push({
        path: path,
      });
    },
    showUserPage() {
      if (JSON.stringify(this.$store.state.loginUser) !== "{}") {
        this.changePath("/user");
      } else {
        this.changePath("/user_nologin");
      }
    },
  },
};
</script>

<style lang="less">
@import "./common.less";
</style>
