import request from "@/util/request";

const memberAuthenticationPrefix = "/v1/member/memberAuthentication";

export default {
  authenticateBrand(params) {
    return request.post(`${memberAuthenticationPrefix}/authenticateBrand`, params);
  },
  authenticateDesignAgency(params) {
    return request.post(`${memberAuthenticationPrefix}/authenticateDesignAgency`, params);
  },
  authenticateDesigner(params) {
    return request.post(`${memberAuthenticationPrefix}/authenticateDesigner`, params);
  },
  authenticateMedia(params) {
    return request.post(`${memberAuthenticationPrefix}/authenticateMedia`, params);
  },
  myAuthentication() {
    return request.get(`${memberAuthenticationPrefix}/myAuthentication`);
  },
};
