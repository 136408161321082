import actions from "@/store/module/comment/action.js";

export default {
  namespaced: true,
  state: {
    // 评论列表
    contentCommentList: [],
    contentCommentPageInfo: {},
    // 根据id获取评论详情
    contentComment: {},
  },
  actions,
};
