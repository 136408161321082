<template>
  <div class="menu_bar_container">
    <van-grid direction="horizontal" :border="false" :column-num="options.length">
      <van-grid-item
        v-for="(item, index) in options"
        :key="index"
        :text="item"
        icon="arrow-down"
        @click="emitParentEvent(item)"
      >
      </van-grid-item>
    </van-grid>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  methods: {
    emitParentEvent(item) {
      this.$emit("handleEvent", item);
    },
  },
};
</script>

<style lang="less" scoped></style>
