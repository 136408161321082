import request from "@/util/request";
const memberDesignerPrefix = "/v1/member/contentSources";

export default {
  designerList: (params) => {
    return request.get(`${memberDesignerPrefix}/designerList`, params);
  },
  designerById: (id) => {
    return request.get(`${memberDesignerPrefix}/${id}`);
  },
  updateInfo: (params) => {
    return request.put(`${memberDesignerPrefix}/updateInfo`, params);
  },
};
